import React, { useState } from 'react';
import { Dialog, TextField, Button } from '@material-ui/core';
import { ORDERED_ADDR_KEYS, FULLNAME } from './constants';
import userCommonStyles from '../utils/UserCommonStyles';

export default ({ open, value, onClose, onConfirm, removeField, isAddress }) => {
  const [tempValues, setTempValues] = useState(value);
  const classes = userCommonStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={!value.street && !value.firstName ? removeField : onClose}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          onConfirm(tempValues);
          onClose();
        }}
      >
        <div className={classes.inputsContainer}>
          {(isAddress ? ORDERED_ADDR_KEYS : FULLNAME).map(subKey => {
            const subVal = tempValues[subKey];
            return (
              <TextField
                required={subKey !== 'apt' && subKey !== 'middleName'}
                key={subKey}
                className={classes.input}
                fullWidth
                label={
                  isAddress
                    ? subKey
                    : { firstName: 'first name', middleName: 'middle name', lastName: 'last name' }[subKey]
                }
                variant="outlined"
                value={subVal}
                onChange={e => {
                  setTempValues({ ...tempValues, [subKey]: e.target.value });
                }}
              />
            );
          })}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={() => {
              if (!value.street && !value.firstName) {
                removeField();
              }
              return onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
