import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreSubscribe } from '../hooks';
import { db } from '../firebase';
import { useConfirm } from 'material-ui-confirm';
import { formatRelative } from 'date-fns';
import Loading from '../components/Loading';
import { StyledCreateIcon, StyledDeleteIcon } from '../components/Icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import containerStyle from './containerStyle';
import userCommonStyles from '../components/User/utils/UserCommonStyles';
import Editor from 'rich-markdown-editor';

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
    addHandbookButton: {
      textDecoration: 'none',
      '& > button': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    table: {
      minHeight: '100%',
    },
    tablePagination: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    tableRow: {
      color: '#747a8f !important',
      height: 78,
      '& .MuiTableCell-root:nth-child(2)': {
        maxWidth: '350px',
      },
      '&:nth-of-type(even)': {
        background: '#f9f9f9',
      },
      '& > td': {
        borderBottom: 'none',
        color: '#747a8f',
        '&:last-child': {
          '& > svg:first-child': {
            '&:hover': {
              background: 'red !important',
              border: `.3px solid red !important`,
              '& > path': {
                fill: 'white',
              },
            },
          },
          '&:last-child > a > svg': {
            '&:hover': {
              background: theme.palette.secondary.main,
              border: `.3px solid ${theme.palette.secondary.main}`,
              '& > path': {
                fill: 'white',
              },
            },
          },
        },
      },
    },
    buttonContainer: {
      whiteSpace: 'nowrap',
    },
  };
});

const FAQ = () => {
  const classes = useStyles();
  const commonClasses = userCommonStyles();
  const [faqs, isLoading] = useFirestoreSubscribe('faq', null, 'order');
  const confirm = useConfirm();

  const deleteFAQ = async faq => {
    await confirm({ description: `This will permanently delete the FAQ ${faq.question}.` });
    db.collection('faq')
      .doc(faq.id)
      .delete();
  };

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const item = faqs.find(faq => faq.id === draggableId);
    // Remove dragged item from the array.
    let newFaqs = [...faqs.slice(0, source.index), ...faqs.slice(source.index + 1)];
    // Create new array with the respective order.
    newFaqs = [...newFaqs.slice(0, destination.index), item, ...newFaqs.slice(destination.index)];
    // Update each item order prop with the new value.
    newFaqs = newFaqs.map((item, order) => {
      return { ...item, order };
    });

    // Update each item order prop in db if the position changed.
    for (const faq of newFaqs) {
      const oldFaq = faqs.find(f => f.id === faq.id);

      if (faq.order === oldFaq.order) {
        continue;
      }
      await db
        .collection('faq')
        .doc(faq.id)
        .update({ order: faq.order });
    }
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle1">Admin</Typography>
      <Typography variant="h4">FAQs</Typography>
      <Typography variant="subtitle2">View and manage all FAQs</Typography>
      <Link className={classes.addHandbookButton} to="/faq/new">
        <Button variant="outlined" color="primary">
          Add FAQ Question
        </Button>
      </Link>
      {isLoading ? (
        <Loading />
      ) : (
        <Paper className={classes.paper} elevation={0}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table stickyHeader aria-label="sticky table" className={classes.table}>
              <TableHead className={commonClasses.tableHead}>
                <TableRow>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="1">
                {provided => (
                  <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
                    {faqs.map((faq, index) => (
                      <Draggable draggableId={faq.id.toString()} index={index} key={faq.id}>
                        {provided => (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            className={classes.tableRow}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            innerRef={provided.innerRef}
                          >
                            <TableCell>{faq.question}</TableCell>
                            <TableCell>
                              <Editor theme={{ background: 'transparent' }} defaultValue={faq.answer} readOnly />
                            </TableCell>
                            <TableCell>{faq.enabled ? 'On' : 'Off'}</TableCell>
                            <TableCell>{formatRelative(faq.updatedAt.toDate(), new Date())}</TableCell>
                            <TableCell align="center" className={classes.buttonContainer}>
                              <StyledDeleteIcon onClick={() => deleteFAQ(faq)} />
                              &nbsp;
                              <Link to={`/faq/${faq.id}`}>
                                <StyledCreateIcon />
                              </Link>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Paper>
      )}
    </Container>
  );
};

export default FAQ;
