import React, { useState, useRef } from 'react';
import { TextField, Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { db } from '../../../firebase';
import useAsync from '../../../hooks/useAsync';
import StyledCreateIcon from '../utils/StyledCreateIcon';
import userCommonStyles from '../utils/UserCommonStyles';

const useStyles = makeStyles(theme => ({
  middleNameSection: {
    position: 'relative',
    borderBottom: `1px solid #f5f5f5`,
  },
  ageEmployerContainer: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  employerSection: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid #f5f5f5`,
    },
  },
  ageSection: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2),
    display: 'grid',
    minWidth: 100,
    padding: theme.spacing(1.1, 2.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 12,
  },
  alternateInfo: {
    padding: theme.spacing(4, 4, 3.5, 5),
    borderRadius: 30,
    border: `1px solid #f5f5f5`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  message: {
    margin: theme.spacing(1),
  },
}));

const currentAge = dob => {
  const currentDay = new Date();
  const dateOfBirth = new Date(dob);
  let age = currentDay.getFullYear() - dateOfBirth.getFullYear();
  const month = currentDay.getMonth() - dateOfBirth.getMonth();
  if (month < 0 || (month === 0 && currentDay.getDate() < dateOfBirth.getDate())) {
    age--;
  }
  return age.toString();
};

const updateAdditionalUserInfo = async ({ userMidName, userEmployer, userAge, id }) => {
  await db
    .collection('users')
    .doc(id)
    .update({ middleName: userMidName, employer: userEmployer, age: currentAge(userAge), dob: userAge });

  return true;
};

const AdditionalInformation = ({ id, middleName, employer, dob }) => {
  const classes = useStyles();
  const sharedClasses = userCommonStyles();
  const [open, setOpen] = useState(false);

  const [userMidName, setMiddleName] = useState(middleName);
  const [userEmployer, setEmployer] = useState(employer);
  const [userAge, setAge] = useState(dob);
  const [{ isLoading, error }, updateInfo] = useAsync(updateAdditionalUserInfo, false);

  const mNameEl = useRef();
  const employerEl = useRef();
  const ageEl = useRef();

  const inputFields = {
    userEmployer: {
      label: 'Employer',
      value: userEmployer,
      inputRef: employerEl,
      className: classes.employerSection,
      onChange: e => {
        setEmployer(e.target.value);
        setOpen(true);
      },
    },
    userAge: {
      label: 'Date of Birth',
      type: 'date',
      value: userAge,
      inputRef: ageEl,
      inputProps: {
        min: '1900-01-01',
        max: `${new Date().toISOString().slice(0, 4) - 18}-${new Date().toISOString().slice(5, 10)}`,
      },
      className: classes.ageSection,
      onChange: e => {
        setAge(e.target.value);
        setOpen(true);
      },
    },
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        updateInfo({ id, userMidName, userEmployer, userAge });
        setOpen();
      }}
    >
      <Grid direction="row" className={classes.alternateInfo} container>
        <Grid md={12} sm={12} xs={12} item className={classes.middleNameSection}>
          <TextField
            className={sharedClasses.textField}
            label={'Middle Name'}
            type="text"
            value={userMidName}
            placeholder={'Enter your middle name'}
            onChange={e => {
              setMiddleName(e.target.value);
              setOpen(true);
            }}
            inputRef={mNameEl}
            onBlur={() => {
              mNameEl.current.disabled = true;
            }}
            InputProps={{
              disableUnderline: true,
              disabled: true,
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
              focused: true,
            }}
          />
          <StyledCreateIcon
            onClick={() => {
              mNameEl.current.disabled = false;
              mNameEl.current.focus();
            }}
          />
        </Grid>
        <Grid container className={classes.ageEmployerContainer}>
          {Object.values(inputFields).map((input, i) => (
            <Grid item key={i} lg={6} md={12} sm={12} xs={12} className={input.className}>
              <TextField
                className={sharedClasses.textField}
                type={input.type}
                label={input.label}
                value={input.value}
                onChange={input.onChange}
                inputRef={input.inputRef}
                onBlur={() => {
                  input.inputRef.current.disabled = true;
                }}
                InputProps={{
                  disableUnderline: true,
                  disabled: true,
                  ...input.inputProps,
                }}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                  focused: true,
                }}
              />
              <StyledCreateIcon
                onClick={() => {
                  input.inputRef.current.disabled = false;
                  input.inputRef.current.focus();
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="center">
        {open && (
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            {isLoading ? <CircularProgress size={20} /> : 'Update'}
          </Button>
        )}
        {error && error.message && (
          <Typography display="block" variant="subtitle2" color="error">
            {error.message}
          </Typography>
        )}
      </Grid>
    </form>
  );
};

export default AdditionalInformation;
