import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  secondaryTitleHeader: {
    color: theme.palette.link,
    marginTop: theme.spacing(3),
    fontSize: theme.typography.fontSize,
  },
  mainTitleHeader: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '2rem',
    marginTop: theme.spacing(1),
  },
  root: {
    width: '80%',
    marginBottom: theme.spacing(10),
  },
  container: {
    '& > h4': {
      padding: theme.spacing(2),
    },
  },
  tableHeader: {
    '& > tr > th': {
      background: theme.palette.primary.main,
      borderBottom: '1px solid #FBFBFC !important',
      fontWeight: 600,
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
  },
  tableRow: {
    '& > td': {
      borderBottom: 'none !important',
      fontWeight: 600,
      color: theme.palette.primary.main,
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
    '&:nth-of-type(even)': {
      background: '#f9f9f9',
    },
  },
  tablePagination: {
    borderTop: '1px solid #FBFBFC',
    background: 'white',
  },
  bold500: {
    fontWeight: 500,
  },
  headCellRight: {
    borderRadius: theme.spacing(0, 1, 0, 0),
    color: 'white',
  },
  headCellLeft: {
    borderRadius: theme.spacing(1, 0, 0, 0),
    color: 'white',
  },
  headCellCenter: {
    color: 'white',
  },
}));

const SummaryTable = ({ title, summaries, percent }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root} elevation={0} square={false}>
        <div className={classes.container}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.headCellLeft} align="left">
                  {title}
                </TableCell>
                <TableCell className={!percent ? classes.headCellRight : classes.headCellCenter} align="center">
                  &#35;
                </TableCell>
                {percent && (
                  <TableCell className={classes.headCellRight} align="center">
                    %
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {summaries.map((summary, i) => (
                <TableRow hover className={classes.tableRow} key={i}>
                  <TableCell align="left">{summary.name}</TableCell>
                  <TableCell align="center">{summary.amount}</TableCell>
                  {percent && <TableCell align="center">{summary.percent}%</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default SummaryTable;
