export default theme => ({
  container: {
    marginTop: theme.spacing(6),
    paddingLeft: `${theme.spacing(12.5)}px !important`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(2)}px !important`,
      height: '100%',
    },
    '& > h6': {
      marginBottom: theme.spacing(1),
    },
    '& > h4': {
      fontWeight: 500,
    },
  },
});
