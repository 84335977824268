import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, Box, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Create as CreateIcon, Person as PersonIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { formatRelative } from 'date-fns';

const useStyledCreateIcon = makeStyles(theme => ({
  icon: {
    border: `1px solid ${theme.palette.link}`,
    borderRadius: '35%',
    padding: theme.spacing(1.4, 1.6),
    cursor: 'pointer',
    fontSize: 17,
    '& > path': {
      fill: theme.palette.link,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `.3px solid ${theme.palette.secondary.main}`,
      '& > path': {
        fill: 'white',
      },
    },
  },
  popover: {
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '0.75rem',
      color: 'green',
      marginRight: theme.spacing(0.25),
    },
  },
  text: {
    color: '#757575',
  },
}));

const StyledCreateIcon = () => {
  const classes = useStyledCreateIcon();
  return <CreateIcon className={classes.icon} />;
};

const StyledUserProfileIcon = () => {
  const classes = useStyledCreateIcon();
  return <PersonIcon className={classes.icon} />;
};

const PopoverBox = () => {
  const classes = useStyledCreateIcon();
  return (
    <Box className={classes.popover} p={1}>
      <Typography variant="caption" className={classes.text}>
        <CheckIcon fontSize="small" />
        Copied!
      </Typography>
    </Box>
  );
};

export default (users, isVisibleOnMobile) => {
  const copyClipboard = e => {
    e.preventDefault();
    const target = e.target;
    const range = document.createRange();
    range.selectNodeContents(target);
    const selection = document.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
  };

  return [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'oldUser',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'fullName',
      label: 'Name',
      options: {
        filter: false,
        customBodyRender: (name, tableMeta) => {
          // eslint-disable-next-line no-unused-vars
          const [_, oldUser] = tableMeta.rowData;
          if (oldUser) {
            return (
              <>
                <span role="img" aria-label="oldman">
                  👴
                </span>{' '}
                {name}
              </>
            );
          }
          return <>{name}</>;
        },
      },
    },
    {
      name: 'primaryEmail',
      label: 'Email',
      options: {
        customBodyRender: email => {
          return (
            <>
              <PopupState variant="popover">
                {popupState => (
                  <>
                    <span title="Copy to Clipboard" color="primary" {...bindTrigger(popupState)}>
                      <span style={{ cursor: 'pointer' }} onClick={copyClipboard}>
                        {email}
                      </span>
                    </span>
                    <Popover
                      elevation={2}
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <PopoverBox />
                    </Popover>
                  </>
                )}
              </PopupState>
            </>
          );
        },
        display: !isVisibleOnMobile,
        filter: false,
      },
    },
    {
      name: 'plan',
      label: 'Membership',
      options: {
        sort: false,
        filter: false,
        display: !isVisibleOnMobile,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        sort: true,
        filter: false,
        customBodyRender: createdAt => {
          const formattedDate = new Date(parseInt(createdAt));
          return <>{formatRelative(formattedDate, new Date())}</>;
        },
      },
    },
    {
      name: 'options',
      label: 'Options',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (_, tableMeta) => {
          const [id, oldUser] = tableMeta.rowData;
          if (oldUser) {
            return <></>;
          }
          return (
            <>
              <Link to={`/users/${id}`}>
                <StyledCreateIcon />
              </Link>
              &nbsp; &nbsp;
              <a
                href={`${process.env.REACT_APP_DASHBOARD_SITE_URL}/alerts?as=${id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <StyledUserProfileIcon />
              </a>
            </>
          );
        },
      },
    },
  ];
};
