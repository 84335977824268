import React from 'react';
import formatDate from 'date-fns/format';
import HandbookToggle from './HandbookToggle';
import { StyledCreateIcon, StyledDeleteIcon } from '../Icons';
import { Link } from 'react-router-dom';

export default (handbooks, deleteHandbook) => {
  return [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: type => (type === 'removalGuide' ? 'Removal Guide' : 'Content'),
      },
    },
    {
      name: 'name',
      label: 'Title',
    },
    {
      name: 'id',
      label: 'Status - Disabled/Enabled',
      options: {
        customBodyRender: (value, tableMeta) => {
          const handbook = handbooks.find(h => h.id === value);
          return <HandbookToggle handbook={handbook} />;
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Last Updated',
      options: {
        customBodyRender: timestamp => (timestamp ? formatDate(timestamp.toDate(), 'MM/dd/yyyy') : ''),
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          const handbook = handbooks.find(h => h.id === value);

          return (
            <>
              <StyledDeleteIcon id="deleteIcon" onClick={() => deleteHandbook(handbook)} />
              &nbsp; &nbsp;
              <Link to={`/handbook/${handbook.id}`}>
                <StyledCreateIcon />
              </Link>
            </>
          );
        },
      },
    },
  ];
};
