import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { functions, auth } from '../../../firebase';
import useAsync from '../../../hooks/useAsync';
import StyledCreateIcon from '../utils/StyledCreateIcon';

const MessageStatus = ({ color, message }) => (
  <Typography variant="subtitle2" color={color} align="center">
    {message}
  </Typography>
);

const useStyles = makeStyles(theme => ({
  textField: {
    '& > div': {
      marginTop: theme.spacing(2),
      '& > input': {
        color: theme.palette.link,
        marginRight: theme.spacing(6),
        '&:focus': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& > label': {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
  },
  cardInputsSection: {
    width: '100%',
    borderRadius: 30,
    border: `1px solid #f5f5f5`,
    padding: theme.spacing(1.25, 0, 0.6),
    marginTop: theme.spacing(2.2),
  },
  emailSection: {
    position: 'relative',
    margin: theme.spacing(2.5, 3.8, 1.3),
    paddingBottom: theme.spacing(1.6),
    borderBottom: `1px solid #f5f5f5`,
  },
  passwordSection: {
    position: 'relative',
    margin: theme.spacing(2.5, 3.8, 2.5),
  },
  gridContiner: {
    height: 200,
    marginTop: theme.spacing(2.2),
  },
  button: {
    display: 'grid',
    minWidth: 100,
    padding: theme.spacing(1.1, 2.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 12,
    margin: `auto 0 0`,
  },
  statusInfo: {
    minHeight: 56,
  },
  bold: {
    fontWeight: 500,
  },
}));

const sendResetPasswordEmail = async ({ email, setSuccessMessage, setErrorMessage }) => {
  try {
    await auth().sendPasswordResetEmail(email);
  } catch (error) {
    setErrorMessage(error.message);
    throw error;
  }

  setSuccessMessage('Email sent.');
  return true;
};

const updateAuthEmail = async ({ id, emailProps, setEmailProps, setSuccessMessage, setErrorMessage }) => {
  try {
    await functions.httpsCallable('updateEmailOrPassword')({ id, email: emailProps.email });
  } catch (error) {
    setEmailProps(prevState => ({ ...prevState, emailChanged: false }));
    setErrorMessage(error.message);
    throw error;
  }

  setEmailProps(prevState => ({ ...prevState, emailChanged: false }));
  setSuccessMessage('Email Updated.');

  return true;
};

const ClientInformation = ({ id, user }) => {
  const classes = useStyles();
  const [emailProps, setEmailProps] = useState({
    email: user.primaryEmail,
    emailChanged: false,
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const emailEl = useRef(null);

  const [{ result: updatedResult, isLoading: isUpdating, error: updateError }, updateInformation] = useAsync(
    updateAuthEmail,
    false
  );
  const [
    { result: sendPasswordResult, isLoading: isSending, error: resetPasswordError },
    triggerSendEmailPassword,
  ] = useAsync(sendResetPasswordEmail, false);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        updateInformation({ id, emailProps, setEmailProps, setSuccessMessage, setErrorMessage });
      }}
    >
      <Typography className={classes.bold} variant="subtitle1" color="primary" align="left">
        Customer Information
      </Typography>

      <Grid container alignItems="center" justify="center">
        <div className={classes.cardInputsSection}>
          <div className={classes.emailSection}>
            <TextField
              className={classes.textField}
              label={'Primary Email'}
              type={'email'}
              value={emailProps.email || ''}
              placeholder={'Enter the email'}
              onChange={e => {
                // keep the event object from being pooled by react.
                e.persist();
                setEmailProps({ email: e.target.value, emailChanged: true });
              }}
              inputRef={emailEl}
              onBlur={() => {
                emailEl.current.disabled = true;
              }}
              fullWidth={true}
              InputProps={{
                disableUnderline: true,
                disabled: true,
              }}
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
                focused: true,
              }}
            />
            <StyledCreateIcon
              onClick={() => {
                emailEl.current.disabled = false;
                emailEl.current.focus();
              }}
            />
          </div>
          <div className={classes.passwordSection}>
            <Button
              style={{ margin: '0 auto', border: '1px solid black' }}
              onClick={() => triggerSendEmailPassword({ email: emailProps.email, setSuccessMessage, setErrorMessage })}
              className={`${classes.button} ${classes.bold}`}
              variant="text"
              color="primary"
            >
              {isSending ? <CircularProgress size={20} /> : 'Reset Password Email'}
            </Button>
          </div>
        </div>
      </Grid>

      <Grid className={classes.statusInfo} container justify="center" alignItems="center">
        {!!emailProps.emailChanged && (
          <Button className={`${classes.button} ${classes.bold}`} variant="contained" color="secondary" type="submit">
            {isUpdating ? <CircularProgress size={20} /> : 'Update'}
          </Button>
        )}
        {(updateError || resetPasswordError) && !emailProps.emailChanged ? (
          <MessageStatus color="error" message={errorMessage} />
        ) : (
          (updatedResult || sendPasswordResult) &&
          !emailProps.emailChanged && <MessageStatus color="secondary" message={successMessage} />
        )}
      </Grid>
    </form>
  );
};

export default ClientInformation;
