import React from 'react';
import { Typography, Grid, Link, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useSytlesStripes = makeStyles(theme => ({
  linkStripe: {
    marginLeft: 'auto',
    textDecoration: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    '& > button': {
      margin: 'unset',
    },
  },
  bold: {
    fontWeight: 500,
  },
  link: {
    color: theme.palette.link,
  },
}));

const Stripe = ({ stripeId }) => {
  const classes = useSytlesStripes();

  return (
    <div>
      <Typography className={classes.bold} variant="body1" display="inline" color="primary">
        Stripe ID
      </Typography>
      <Grid container spacing={0}>
        <span className={classes.link}>{stripeId || "Client dosen't have a stripe registered."}</span>
        {stripeId && (
          <Link
            className={`${classes.linkStripe} ${classes.bold}`}
            href={`${process.env.REACT_APP_STRIPE_URL}/customers/${stripeId}`}
            target="_blank"
            rel="noopener"
          >
            <Button variant="outlined" color="primary">
              View User In Stripe
            </Button>
          </Link>
        )}
      </Grid>
    </div>
  );
};

export default Stripe;
