import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreSubscribe } from '../hooks';
import { db } from '../firebase';
import { useConfirm } from 'material-ui-confirm';
import Loading from '../components/Loading';

import containerStyle from './containerStyle';
import ItemsTable from '../components/ItemsTable';
import columns from '../components/Handbook/columns';

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
    addHandbookButton: {
      textDecoration: 'none',
      '& > button': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    table: {
      minHeight: '100%',
    },
    tablePagination: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    tableRow: {
      color: '#747a8f !important',
      height: 78,
      '&:nth-of-type(even)': {
        background: '#f9f9f9',
      },
      '& > td': {
        borderBottom: 'none',
        color: '#747a8f',
      },
      '&:hover > td': {
        color: theme.palette.primary.main,
      },
      '&:hover td:last-child svg': {
        background: theme.palette.secondary.main,
        border: `.3px solid ${theme.palette.secondary.main}`,
        '& > path': {
          fill: 'white',
        },
      },
      '&:hover td:last-child': {
        '& > svg': {
          '&:first-child': {
            background: 'red !important',
            border: '.3px solid red !important',
          },
        },
      },
    },
    tableHead: {
      '& > tr': {
        '& > th': {
          background: 'none',
          textAlign: 'center',
          '&:first-child': {
            display: 'table-cell !important',
          },
        },
      },
    },
    tableCell: {
      textAlign: 'center',
    },
  };
});

const Handbooks = ({ location }) => {
  const classes = useStyles();
  const [handbooks, isLoading] = useFirestoreSubscribe('handbooks');
  const confirm = useConfirm();

  const deleteHandbook = async handbook => {
    await confirm({ description: `This will permanently delete the handbook ${handbook.name}.` });
    db.collection('handbooks')
      .doc(handbook.id)
      .delete();
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle1">Admin</Typography>
      <Typography variant="h4">Privacy Handbook</Typography>
      <Typography variant="subtitle2">View and manage all Privacy Handbook content</Typography>
      <Link className={classes.addHandbookButton} to={{ pathname: '/handbook/new', hash: '#' }}>
        <Button variant="outlined" color="primary">
          New Guide
        </Button>
      </Link>
      <Link className={classes.addHandbookButton} to="/handbook/new">
        <Button variant="outlined" color="primary">
          New Content
        </Button>
      </Link>
      {isLoading ? <Loading /> : <ItemsTable columns={columns(handbooks, deleteHandbook)} items={handbooks} />}
    </Container>
  );
};

export default Handbooks;
