import React, { useState, useEffect, useRef, useCallback } from 'react';
import { OutlinedInput, InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import EditDialog from './EditDialog';
import { ORDERED_ADDR_KEYS, FULLNAME } from './constants';
import { InputDelete } from '../../../images';

import PhoneNumberInput from '../utils/PhoneNumberInput';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(1.5),
    color: `${theme.palette.grey[400]} !important`,
    '&:hover': {
      color: 'black !important',
    },
    '& input:focus': {
      color: 'black !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[200],
    },
  },
  deleteButton: {
    '& svg': {
      transitionDuration: 200,
      transitionProperty: 'color',
      fill: theme.palette.grey[300],
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
    '&:hover svg': {
      fill: 'black',
    },
  },
}));

export default ({ value, saveValues, onDelete, isAddress, isPhoneNumber, isAlias }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const currentObjectValues = keys =>
    keys
      .map(key => value[key])
      .filter(val => val)
      .join(', ');

  const displayVal = isAddress
    ? currentObjectValues(ORDERED_ADDR_KEYS)
    : isAlias
    ? currentObjectValues(FULLNAME)
    : inputValue;

  const handleObjectEditing = useCallback(() => {
    if (!isAlias && !isAddress) return;
    setDialogOpen(true);
  }, [isAlias, isAddress, setDialogOpen]);

  // If the new input's value is empty, focus the input (or open the editing dialog if value is an object).
  useEffect(() => {
    if (!displayVal) {
      if (isAddress || isAlias) {
        handleObjectEditing();
      } else {
        inputRef.current.focus();
      }
    }
  }, [displayVal, handleObjectEditing, isAlias, isAddress]);

  //This will allow deleting the selected input.
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <OutlinedInput
        variant="outlined"
        inputRef={inputRef}
        className={classes.input}
        fullWidth
        value={displayVal}
        readOnly={isAddress}
        onClick={handleObjectEditing}
        onChange={e => setInputValue(e.target.value)}
        onBlur={() => (!inputValue ? onDelete() : (!isAlias || !isAddress) && saveValues(inputValue))}
        // If not a phone number we need to suply the input type to prevent error.
        inputComponent={isPhoneNumber ? PhoneNumberInput : 'input'}
        endAdornment={
          <InputAdornment position="end" className={classes.deleteButton}>
            <IconButton
              onClick={e => {
                //This will prevent opening the address modal when deleting a address field
                e.stopPropagation();
                onDelete();
              }}
            >
              <InputDelete />
            </IconButton>
          </InputAdornment>
        }
      />
      {(isAddress || isAlias) && (
        <EditDialog
          value={value}
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={saveValues}
          removeField={onDelete}
          isAddress={isAddress}
        />
      )}
    </>
  );
};
