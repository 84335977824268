import React, { useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SiteSettingsPanel from '../components/SiteSettingsPanel';
import SitesTable from '../components/SiteSettingsPanel/SitesTable';
import Loading from '../components/Loading';
import { useFirestoreSubscribe } from '../hooks';
import containerStyle from './containerStyle';

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
  };
});

const SiteSettings = () => {
  const classes = useStyles();
  const [site, setSite] = useState(null);

  const [sites, isSitesLoading] = useFirestoreSubscribe('sites');
  const [handbooks, isHandbooksLoading] = useFirestoreSubscribe('handbooks');

  if (isSitesLoading || isHandbooksLoading) {
    return <Loading />;
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle2" color="textSecondary">
        Admin
      </Typography>
      <Typography variant="h4" color="primary">
        Site Settings
      </Typography>
      <Typography variant="subtitle2">Set your preferred scan/remove mix based on membership plan</Typography>

      <SitesTable setSite={setSite} result={sites} isLoading={isSitesLoading} sites={sites} />

      <SiteSettingsPanel
        open={Boolean(site)}
        onClose={() => {
          setSite(null);
        }}
        site={site}
        handbooks={handbooks}
        isLoading={isHandbooksLoading}
      />
    </Container>
  );
};

export default SiteSettings;
