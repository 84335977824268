import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  TablePagination,
  OutlinedInput,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';

import Switch from './ScanningSwitch';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 800,
    marginTop: theme.spacing(5),
    textAlign: 'center', //To centralize the circularProgress
  },
  editButton: {
    border: `1px solid ${theme.palette.link}`,
    borderRadius: '25%',
    padding: theme.spacing(1),
    cursor: 'pointer',
    '& > svg': {
      color: theme.palette.link,
      position: 'relative',
      top: 5,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `.3px solid ${theme.palette.secondary.main}`,
      '& > svg': {
        fill: 'white',
      },
    },
  },
  container: {
    '& > h4': {
      padding: theme.spacing(2),
    },
  },
  tableHeader: {
    borderRadius: theme.spacing(2, 2, 0, 0),
    '& > tr > th': {
      background: theme.palette.primary.main,
      color: 'white',
      borderBottom: '1px solid #FBFBFC !important',
      padding: theme.spacing(3),
      '&:first-child': {
        borderRadius: theme.spacing(1.5, 0, 0, 0),
      },
      '&:last-child': {
        borderRadius: theme.spacing(0, 1.5, 0, 0),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
  },
  tableRow: {
    '& > td': {
      borderBottom: 'none !important',
      fontWeight: 600,
      color: theme.palette.primary.main,
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
    '&:nth-of-type(even)': {
      background: '#f9f9f9',
    },
  },
  tablePagination: {
    borderTop: '1px solid #FBFBFC',
    background: 'white',
  },
  inputSearch: {
    width: '100%',
    maxWidth: 540,
    marginTop: theme.spacing(3),
    background: 'white',
    borderRadius: 30,
    '& > fieldset': {
      border: `.5px solid ${theme.palette.light}`,
    },
  },
}));

const SitesTable = ({ setSite, result, isLoading, sites }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [searchSite, setSearchSite] = React.useState('');

  const classes = useStyles();

  const siteResults =
    Boolean(result) && result.filter(site => site.label.toLowerCase().includes(searchSite.toLowerCase()));

  const totals = sites.reduce(
    (total, current) => {
      for (const key of Object.keys(total)) {
        total[key] = total[key] + (current.userScanSettings.internal[key].time === 0 ? 0 : 1);
      }
      return total;
    },
    { trial: 0, premium: 0, pro: 0, vip: 0, executive: 0 }
  );

  return (
    <>
      <OutlinedInput
        placeholder="Search"
        className={classes.inputSearch}
        startAdornment={<SearchIcon />}
        value={searchSite}
        onChange={event => {
          setSearchSite(event.target.value);
          setPage(0);
        }}
      />

      <Paper className={classes.root} elevation={0} square={false}>
        <div className={classes.container}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {Object.keys(totals).map(key => (
                  <TableCell key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {Object.values(totals).map((total, i) => (
                  <TableCell key={i}>{total}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>

      <Paper className={classes.root} elevation={0} square={false}>
        {isLoading ? (
          <CircularProgress size={45} />
        ) : (
          <div className={classes.container}>
            {siteResults.length ? (
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell align="left">Site Label</TableCell>
                    <TableCell align="right">Scanning</TableCell>
                    <TableCell align="right">Removing - Off/On</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {siteResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(site => {
                    return (
                      <TableRow hover key={site.id} className={classes.tableRow}>
                        <TableCell align="left">{site.label}</TableCell>
                        <TableCell align="right">{site.isScanning ? 'On' : 'Off'}</TableCell>
                        <TableCell align="right">
                          {site.hasRemoval && <Switch site={site} field="isRemoving" />}
                        </TableCell>
                        <TableCell align="right">
                          <span
                            className={classes.editButton}
                            onClick={() => {
                              setSite(site);
                            }}
                          >
                            <EditIcon />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h4">No Search Results</Typography>
            )}
            <TablePagination
              className={classes.tablePagination}
              rowsPerPageOptions={siteResults.length > 5 ? [5, 10, 25, 100] : []}
              component="div"
              count={siteResults.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(event, newPage) => setPage(newPage)}
              onChangeRowsPerPage={event => {
                setRowsPerPage(+event.target.value);
                setPage(0);
              }}
            />
          </div>
        )}
      </Paper>
    </>
  );
};

export default SitesTable;
