import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: 40,
    '& .MuiInputBase-input': {
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
}));

export default ({ setSelectedScanTimes, selectedScanTimes }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.inputField}
      value={selectedScanTimes}
      type="number"
      onChange={e => {
        setSelectedScanTimes(+e.target.value < 0 ? 0 : +e.target.value);
      }}
    />
  );
};
