import React, { useState } from 'react';
import { Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { startOfMonth } from 'date-fns';

import ItemsTable from '../components/ItemsTable';
import Loading from '../components/Loading';
import SummaryTable from '../components/SummaryTable';
import { useFirestoreSubscribe } from '../hooks';
import columns from '../components/Analytics/Columns';
import containerStyle from './containerStyle';
import { getPlan } from '../components/Users/utils';
import { ALERT_TYPES } from '../alertTypes';

const useStyles = makeStyles(containerStyle);

const getMembershipSummary = users =>
  users.reduce((totals, user) => {
    const name = getPlan(user.plan);

    if (!totals[name]) {
      totals[name] = { amount: 0, name };
    }
    const amount = totals[name].amount + 1;
    const percent = Math.round((amount / users.length) * 100);
    totals[name] = { name, amount, percent, plan: user.plan };
    return totals;
  }, {});

const getUsageSummary = (sites, requestsCount, month) => {
  for (const site of sites) {
    if (!site.scans) {
      site.scans = { [month]: 0 };
    }
    if (!site.recordsFound) {
      site.recordsFound = { [month]: 0 };
    }
    if (!site.removals) {
      site.removals = { [month]: 0 };
    }
  }

  return sites.reduce(
    (totals, site) => {
      totals.scans.amount = totals.scans.amount + (site.scans[month] || 0);
      totals.recordsFound.amount = totals.recordsFound.amount + (site.recordsFound[month] || 0);
      if (site.hasRemoval && site?.recordsFound[month]) {
        totals.removals.amount = totals.removals.amount + (site.removals[month] + site.recordsFound[month] || 0);
      }
      return totals;
    },
    {
      scans: { amount: 0, name: 'Total Scans' },
      recordsFound: { amount: 0, name: 'Total Records Found' },
      removals: { amount: requestsCount, name: 'Total Removals' },
    }
  );
};

const getSitesSummary = (sites, month) => {
  let siteTotals = sites;
  for (const [i, site] of siteTotals.entries()) {
    if (site.hasRemoval && site.recordsFound) {
      const monthlyRemovals = site.recordsFound[month] + (site.removals[month] || 0);
      siteTotals[i] = { ...site, removals: { [month]: monthlyRemovals } };
    }
  }
  return siteTotals;
};

const getAlertSummary = alerts => {
  const totals = ALERT_TYPES.reduce((types, type) => {
    types[type.id] = { amount: 0, name: type.label };
    return types;
  }, {});

  let alertTotals = alerts.reduce((totals, alert) => {
    totals[alert.type].amount = totals[alert.type].amount + 1;
    return totals;
  }, totals);

  alertTotals['removed'].amount = alertTotals['requested'].amount + alertTotals['removed'].amount;

  const { requested, ...alertSummary } = alertTotals;

  return alertSummary;
};

const Analytics = () => {
  const classes = useStyles();
  const [sites, isSitesLoading] = useFirestoreSubscribe('sites');
  const [users, isUsersLoading] = useFirestoreSubscribe('users');
  const [alerts, isAlertsLoading] = useFirestoreSubscribe('alerts');
  const [requestsForCurrentMonth, isRequestsForCurrentMonthLoading] = useFirestoreSubscribe('alerts', [
    ['type', '==', 'requested'],
    ['type', '==', 'removed'],
    ['type', '==', 'adminOptOut'],
    ['type', '==', 'manual'],
    ['createdAt', '>=', startOfMonth(new Date())],
  ]);
  const isLoading = isSitesLoading || isUsersLoading || isAlertsLoading || isRequestsForCurrentMonthLoading;
  const currentDate = new Date();
  //todo: we've got this in state beacuse we'll add a selector for choosing month
  const [month] = useState(`${currentDate.getFullYear()}_${currentDate.getMonth()}`);

  if (isLoading) {
    return <Loading />;
  }

  const membershipSummary = getMembershipSummary(users);
  const usageSummary = getUsageSummary(sites, requestsForCurrentMonth.length, month);
  const sitesSummary = getSitesSummary(sites, month);
  const alertSummary = getAlertSummary(alerts);

  const orderedTypes = ['free', 'trial', 'premium', 'pro', 'vip', 'executive'];

  return (
    <Container className={classes.container} maxWidth={false}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="subtitle1" className={classes.secondaryTitleHeader}>
            Admin
          </Typography>
          <Typography variant="h4" className={classes.mainTitleHeader}>
            Analytics
          </Typography>
          <Typography variant="subtitle2" className={classes.secondaryTitleHeader}>
            View the total scans, records found and removers activity for the current month
          </Typography>
          <section>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SummaryTable title="Usage Summary - This Month" summaries={Object.values(usageSummary)} />
                <SummaryTable title="Alert Summary - Total" summaries={Object.values(alertSummary)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <SummaryTable
                  title="Membership Summary - Total"
                  summaries={Object.values(membershipSummary).sort((a, b) => {
                    const sortValue = orderedTypes.indexOf(a.plan.type) - orderedTypes.indexOf(b.plan.type);

                    if (sortValue !== 0) {
                      return sortValue;
                    }
                    return a.plan.price - b.plan.price;
                  })}
                  percent={true}
                />
              </Grid>
            </Grid>
          </section>
          <section>
            <ItemsTable title="Sites Summary - This Month" columns={columns(month)} items={sitesSummary} />
          </section>
        </>
      )}
    </Container>
  );
};

export default Analytics;
