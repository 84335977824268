import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  switchStyles: {
    '&.MuiSwitch-root': {
      '& .MuiButtonBase-root,': {
        '&.MuiSwitch-switchBase': {
          color: theme.palette.link,
        },
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
        '&.MuiSwitch-track': {
          background: '#E1E1E1',
          opacity: 'unset',
        },
      },
    },
  },
}));
