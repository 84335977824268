export const ALERT_TYPES = [
  {
    id: 'notFound',
    label: 'No Record Found',
  },
  {
    id: 'unsupported',
    label: 'Record Found',
  },
  {
    id: 'requested',
    label: 'Pending Removal',
  },
  { id: 'manual', label: 'Manually Removed' },
  { id: 'adminOptOut', label: 'Admin Removed' },
  { id: 'removed', label: 'Auto-Removed' },
  { id: 'notMe', label: 'Not a Match' },
];
