import React from 'react';
import { Container, CircularProgress, Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import ProfileInformation from '../components/User/ProfileInformation';
import Vault from '../components/User/Vault/index';
import Membership from '../components/User/Membership';
import Alerts from '../components/User/Alerts';
import Analytics from '../components/User/Analytics';

import { useFirestoreSubscribeDoc, useFirestoreSubscribe } from '../hooks';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    '& > section': {
      marginTop: theme.spacing(10),
      '&:nth-child(2)': {
        marginTop: theme.spacing(2),
      },
    },
  },
  linkAllUsers: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.link,
    transition: 'color 100ms ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '& > svg': {
      fontSize: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
    },
  },
  loading: {
    marginTop: theme.spacing(5),
    height: 200,
    boxShadow: `0px 1px 5px #f5f5f5`,
  },
}));

const ProcessUser = ({ objDisplay, classes }) => (
  <Grid className={classes.loading} container justify="center" alignItems="center">
    {objDisplay}
  </Grid>
);

const User = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const [result, initializing, error] = useFirestoreSubscribeDoc('users', id);
  const [alerts, isLoading] = useFirestoreSubscribe('alerts', ['userId', '==', id]);

  // The <section> in the childs component needs to be existing for the equal spacing to work.
  return (
    <Container className={classes.container} maxWidth="md">
      <Link to="/users" className={classes.linkAllUsers}>
        <ArrowBackIosIcon />
        Back to all users
      </Link>
      {initializing || isLoading ? (
        <ProcessUser classes={classes} objDisplay={<CircularProgress size={40} />} />
      ) : result && Object.keys(result).length ? (
        [ProfileInformation, Vault, Membership, Analytics, Alerts].map((Component, i) => (
          <Component key={i} id={id} user={result} alerts={alerts} />
        ))
      ) : (
        <Paper className={classes.loading} elevation={0}>
          <ProcessUser classes={classes} objDisplay={"The user dosen't exist"} />
          {error && <div>{error.message}</div>}
        </Paper>
      )}
    </Container>
  );
};

export default User;
