import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

let config;

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config = {
    apiKey: 'AIzaSyDTl1BI80brGpof1HKKWXc6H_Aa2bJUXk0',
    authDomain: 'safe-shepherd.firebaseapp.com',
    databaseURL: 'https://safe-shepherd.firebaseio.com',
    projectId: 'safe-shepherd',
    storageBucket: 'safe-shepherd.appspot.com',
    messagingSenderId: '80822325238',
    appId: '1:80822325238:web:7d73db73a47e0c6620c1d7',
    measurementId: 'G-300G38SYRR',
  };
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  config = {
    apiKey: 'AIzaSyD3Ws-1KAYFWbCPoTWsFilvF05F_yBcNH0',
    authDomain: 'safeshepherd-staging.firebaseapp.com',
    databaseURL: 'https://safeshepherd-staging.firebaseio.com',
    projectId: 'safeshepherd-staging',
    storageBucket: 'safeshepherd-staging.appspot.com',
    messagingSenderId: '979601889841',
    appId: '1:979601889841:web:80dd0c365a5ed5f28b9310',
  };
} else {
  config = {
    apiKey: 'AIzaSyDI0ZZA1JtuRp1AqqgFFB0pKzHkKOhYEA0',
    authDomain: 'safeshepherd-dev.firebaseapp.com',
    databaseURL: 'https://safeshepherd-dev.firebaseio.com',
    projectId: 'safeshepherd-dev',
    storageBucket: 'safeshepherd-dev.appspot.com',
    messagingSenderId: '167484047154',
    appId: '1:167484047154:web:13f675a4889bb05d2a212b',
  };
}

firebase.initializeApp(config);

export default firebase;
export const auth = firebase.auth;
export const functions = firebase.functions();
export const db = firebase.firestore();
export const storage = firebase.storage();

export const onAuthStateChange = callback => {
  return firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      //this is where we check if it's an admin or not
      const { uid } = user;
      const doc = await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .get();
      const isAdmin = doc.exists ? doc.data().isAdmin : false;
      callback({ uid, isAdmin, loggedIn: true });
    } else {
      callback({ loggedIn: false });
    }
  });
};

export const login = (username, password) => {
  firebase.auth().signInWithEmailAndPassword(username, password);
};
export const logout = () => {
  firebase.auth().signOut();
};
// TODO: unfortunately with local frontend dev right now, we still have to use remote firebase app, because firebase emulator doesn't support Firebase Auth...
//       once we figure out a good workaround, ideally we'd still like to use local firestore instance for local dev.
// if (process.env.NODE_ENV === 'development') {
//   db.settings({
//     host: 'firebase:8080',
//     ssl: false,
//   });
// } else if (process.env.NODE_ENV === 'test') {
//   db.settings({
//     servicePath: undefined,
//     host: 'localhost:8081',
//     ssl: false,
//   });
// }
