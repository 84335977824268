import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import commonSwitchStyles from '../SiteSettingsPanel/utils/commonSwitchStyles';
import { useAsync } from '../../hooks';
import { db } from '../../firebase';

const HandbookToggle = ({ handbook }) => {
  const [isDisabled, setIsDisabled] = useState(handbook['isDisabled']);

  const classes = commonSwitchStyles();

  // eslint-disable-next-line no-unused-vars
  const [_, handleClick] = useAsync(async () => {
    await db
      .collection('handbooks')
      .doc(handbook.id)
      .update({ isDisabled: !handbook['isDisabled'] });
  });

  return (
    <Switch
      className={classes.switchStyles}
      checked={!isDisabled}
      onClick={() => {
        handleClick(handbook.id, !handbook['isDisabled']);
        setIsDisabled(!isDisabled);
      }}
    />
  );
};

export default HandbookToggle;
