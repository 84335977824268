import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropertyEditor from './PropertyEditor';
import userStylesCommon from '../utils/UserCommonStyles';

import { db } from '../../../firebase';
import useAsync from '../../../hooks/useAsync';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 500,
  },
  paper: {
    minHeight: 400,
  },
  gridContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  gridItem: {
    padding: 32,
  },
  gridItemPaper: {
    padding: 32,
    borderRadius: 24,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  errorText: {
    textAlign: 'center',
    paddingBottom: 16,
  },
}));

const changeUserPII = async (id, user, key, newVals) => {
  await db
    .collection('users')
    .doc(id)
    .update({
      PII: { ...user.PII, ...{ [key]: newVals } },
    });

  return true;
};

const Vault = ({ id, user }) => {
  const classes = useStyles();
  const classesCommon = userStylesCommon();

  const [{ error }, handleSave] = useAsync(changeUserPII);

  return (
    <section>
      <Grid container alignItems="center">
        <Typography className={classes.header} variant="h4" display="inline" color="primary">
          Vault
        </Typography>
      </Grid>
      <Paper className={`${classes.paper} ${classesCommon.paper}`} elevation={0}>
        <Grid className={classes.gridContainer} container justify="center">
          {user.PII &&
            Object.keys(user.PII).map(key => (
              <Grid item md={6} className={classes.gridItem} key={key}>
                <Paper className={classes.gridItemPaper} elevation={0}>
                  <PropertyEditor
                    fieldName={key}
                    values={user.PII[key]}
                    isAlias={key === 'aliases'}
                    isAddress={key === 'addresses'}
                    isPhoneNumber={key === 'phoneNumbers'}
                    saveValues={newVals => {
                      handleSave(id, user, key, newVals);
                    }}
                  />
                </Paper>
              </Grid>
            ))}
        </Grid>
        {error && (
          <Typography variant="subtitle2" className={classes.errorText} color="error">
            {error.message}
          </Typography>
        )}
      </Paper>
    </section>
  );
};

export default Vault;
