import React, { useState } from 'react';
import {
  Container,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import Editor from 'rich-markdown-editor';
import { db } from '../firebase';
import Loading from '../components/Loading';
import userCommonStyles from '../components/User/utils/UserCommonStyles';

import getUserFirebaseDoc from '../hooks/useFirestoreSubscribeDoc';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    '& > section': {
      marginTop: theme.spacing(10),
      '&:nth-child(2)': {
        marginTop: theme.spacing(2),
      },
    },
  },
  linkAllUsers: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.link,
    transition: 'color 100ms ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '& > svg': {
      fontSize: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
    },
  },
  editor: {
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1.5),
    width: '90%',
    padding: theme.spacing(2.5, 5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    display: 'grid',
    minWidth: 100,
    padding: theme.spacing(1.1, 2.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 12,
    margin: `auto 0 0`,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    '& > *:first-child': {
      marginBottom: theme.spacing(2),
    },
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > button': {
      width: 150,
    },
  },
}));

const AddEditFAQ = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const sharedClasses = userCommonStyles();

  const [result, initializing] = getUserFirebaseDoc('faq', id);
  const [updatedQuestion, setUpdatedQuestion] = useState(result.name);
  const [updatedAnswer, setUpdatedAnswer] = useState(result.content);
  const [updatedEnabled, setUpdatedEnabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [initializedBoolean, setInitializedBoolean] = useState(false);

  const history = useHistory();

  const updateFAQ = async (id, question, answer, enabled) => {
    setIsUpdating(true);
    if (id === 'new') {
      await db.collection('faq').add({ question, answer, updatedAt: new Date(), enabled, order: 0 });
      return history.replace('/faq');
    } else {
      await db
        .collection('faq')
        .doc(id)
        .update({ question, answer, updatedAt: new Date(), enabled });
    }
    setIsUpdating(false);
    return history.replace('/faq');
  };

  if (!!initializing) {
    return <Loading />;
  }

  if (!initializedBoolean) {
    //this is a hacky way to get the switch working since you can't use defaultValue.
    setUpdatedEnabled(id === 'new' || result.enabled);
    setInitializedBoolean(true);
    return <Loading />;
  }

  return (
    <Container className={classes.container} maxWidth="md">
      <Link to="/faq" className={classes.linkAllUsers}>
        <ArrowBackIosIcon />
        Back to FAQs
      </Link>
      <Typography variant="h4" className={classes.mainTitleHeader}>
        {id === 'new' ? 'Create' : 'Edit'} FAQ Question
      </Typography>
      <Paper className={classes.paper} elevation={0}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} className={classes.inputsContainer}>
            <TextField
              className={sharedClasses.textField}
              label="Question"
              defaultValue={result.question}
              onChange={e => setUpdatedQuestion(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.editorContainer}>
            <Editor defaultValue={result.answer} className={classes.editor} onChange={setUpdatedAnswer} />

            <FormControlLabel
              control={
                <Switch
                  checked={updatedEnabled}
                  onChange={() => setUpdatedEnabled(!updatedEnabled)}
                  color="secondary"
                />
              }
              label="Enabled"
            />
            <Button
              className={`${classes.button}`}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isUpdating}
              onClick={() =>
                updateFAQ(
                  result.docId || 'new',
                  updatedQuestion || result.question,
                  updatedAnswer || result.answer,
                  updatedEnabled
                )
              }
            >
              {isUpdating ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AddEditFAQ;
