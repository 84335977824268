import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import sharedSwitchStyles from './utils/commonSwitchStyles';
import { useAsync } from '../../hooks';
import { db } from '../../firebase';

const setToggle = async (id, field, result) => {
  await db
    .collection('sites')
    .doc(id)
    .update({ [field]: result });

  return true;
};

const ScanningSwitch = ({ site, field }) => {
  const [isEnabled, setIsEnabled] = useState(site[field]);

  const classes = sharedSwitchStyles();

  // eslint-disable-next-line no-unused-vars
  const [_, handleClick] = useAsync(() => setToggle(site.id, field, !site[field]));

  useEffect(() => {
    setIsEnabled(site[field]);
  }, [site, field]);

  return (
    <Switch
      className={classes.switchStyles}
      checked={isEnabled}
      onClick={() => {
        handleClick(site.id, !site[field]);
        setIsEnabled(!isEnabled);
      }}
    />
  );
};

export default ScanningSwitch;
