import React, { useEffect, useState } from 'react';
import { useMediaQuery, LinearProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import columnsConfig from './usersTableColumnsConfig';

import MUIDataTable from 'mui-datatables';

import { functions } from '../../firebase';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  tableRow: {
    color: '#747a8f !important',
    height: 78,
    '&:nth-of-type(even)': {
      background: '#f9f9f9',
    },
    '& > td': {
      borderBottom: 'none',
      color: '#747a8f',
    },
  },
  tableCell: {
    textAlign: 'center',
  },
  tableContainer: {
    '& .MuiToolbar-root': {
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          margin: theme.spacing(0.6),
        },
      },
    },
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            background: theme.palette.primary.main,
            color: 'white',
            '& span': {
              '& svg > path': { fill: 'white' },
              '& div': {
                alignItems: 'center',
                '& div': {
                  color: 'white',
                },
              },
            },
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(even)': {
            background: '#f9f9f9',
          },
          '& .MuiTableCell-body': {
            borderBottom: 'none !important',
          },
        },
      },
    },
  },
}));

const UsersTable = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    page: 0,
    rowsPerPage: 10,
    sortOrder: { name: 'createdAt', direction: 'desc' },
  });

  const debouncedSearchParams = useDebounce(searchParams, 500);

  useEffect(() => {
    const fetchUsers = async () => {
      const { page, rowsPerPage, sortOrder, searchText } = debouncedSearchParams;
      setIsLoading(true);

      const params = {
        from: page * rowsPerPage,
        size: rowsPerPage,
        trackTotalHits: true,
      };

      if (Boolean(searchText)) {
        params.q = searchText;
      }

      if (Boolean(sortOrder?.name)) {
        const name = ['fullName', 'primaryEmail'].includes(sortOrder.name)
          ? `${sortOrder.name}.keyword`
          : sortOrder.name;
        params.sort = `${name}:${sortOrder.direction}`;
      }

      try {
        const result = await functions.httpsCallable('searchUser')(params);
        const rawUsers = result?.data?.body?.hits?.hits;
        const users = rawUsers.map(u => ({ id: u._id, ...u._source }));

        setTotal(result?.data?.body?.hits?.total?.value);
        setUsers(users);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fetchUsers();
  }, [debouncedSearchParams]);
  const isVisibleOnMobile = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <section>
        {Boolean(isLoading) && <LinearProgress />}
        <MUIDataTable
          className={classes.tableContainer}
          data={users}
          columns={columnsConfig(users, isVisibleOnMobile)}
          options={{
            count: total, //todo: get this from elasticsearch?
            selectableRows: 'none',
            print: false,
            download: false,
            sort: true,
            rowsPerPage: searchParams.rowsPerPage,
            rowsPerPageOptions: [10, 25, 50, 100],
            elevation: 0,
            responsive: 'stacked',
            serverSide: true,
            filter: false,
            viewColumns: false,
            sortOrder: searchParams.sortOrder,
            onTableChange: (action, tableState) => {
              if (action !== 'propsUpdate') {
                setIsLoading(true);
                setSearchParams(tableState);
              }
            },
          }}
        />
      </section>
    </>
  );
};

export default UsersTable;
