import React from 'react';
import NumberFormat from 'react-number-format';

const PhoneNumberInput = ({ inputRef, onChange, value, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      onChange={phone => onChange(phone)}
      format="(###) ###-####"
    />
  );
};

export default PhoneNumberInput;
