import React, { useState } from 'react';
import { Typography, Button, MenuItem, Menu, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useAsync from '../../../hooks/useAsync';
import { useConfirm } from 'material-ui-confirm';

const useStylesPlanDiscount = makeStyles(theme => ({
  dropDownButton: {
    minWidth: 250,
    height: 50,
    margin: theme.spacing(2, 0, 0, 0),
    border: `1px solid ${theme.palette.link}`,
    color: theme.palette.link,
    display: 'flex',
    justifyContent: 'flex-start',
    '& > span:first-child': {
      paddingLeft: theme.spacing(1),
      '& > span': {
        marginLeft: 'auto',
        paddingRight: theme.spacing(1.6),
      },
    },
  },
  menuItem: {
    width: 250,
  },
  bold: {
    fontWeight: 500,
  },
}));

const PlanDiscount = ({ id }) => {
  const classes = useStylesPlanDiscount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState({ name: 'Select Coupon' });
  const [isEdited, setIsEdited] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const confirm = useConfirm();

  const [{ isLoading, error }, adminApplyCoupon] = useAsync(async () => {
    await confirm({
      title: 'Are you sure?',
      description: `Are you sure you want to apply the coupon ${selectedCoupon.name} to the user?`,
    });
    // await functions.httpsCallable('adminApplyCoupon')({ coupon: selectedCoupon.id, userId: id });
    setSuccessMessage(`${selectedCoupon.name} coupon applied`);
  });

  const coupons = [
    {
      name: '75% off month',
      id: 'vrvVrROh',
    },
    {
      name: '50% off month',
      id: 'eGk79Vgx',
    },
    {
      name: '25% off month',
      id: 'F5Tb4Wi2',
    },
    {
      name: '10% off month',
      id: 'k00W2xIl',
    },
    {
      name: 'Free month',
      id: '73Z2vcoU',
    },
    {
      name: 'Free year',
      id: 'IcpmrmEl',
    },
    {
      name: 'Free forever',
      id: 'Z2D4nMud',
    },
    {
      name: '75% off forever',
      id: 'hrfoMULy',
    },
    {
      name: '50% off forever',
      id: 'ybyOxBQc',
    },
    {
      name: '25% off forever',
      id: 'FETJqEnb',
    },
    {
      name: '10% off forever',
      id: 'by7FHLNC',
    },
  ];

  return (
    <div>
      <Typography className={classes.bold} color="primary" variant="body1" display="block">
        Apply Coupon
      </Typography>

      <Button
        className={classes.dropDownButton}
        aria-controls="plan-discount"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ExpandMoreIcon />}
      >
        {selectedCoupon.name}
      </Button>
      <Menu id="plan-discount" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {coupons.map(coupon => (
          <MenuItem
            key={coupon.id}
            className={classes.menuItem}
            selected={selectedCoupon.id === coupon.id}
            onClick={() => {
              setSelectedCoupon(coupon);
              setIsEdited(true);
              setAnchorEl(null);
            }}
          >
            {coupon.name}
          </MenuItem>
        ))}
      </Menu>
      {(isEdited || isLoading) && (
        <div className={classes.confirmChangesContainer}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                adminApplyCoupon();
                setIsEdited(false);
              }}
            >
              {isLoading ? <CircularProgress className={classes.loading} size={21} /> : 'Apply Coupon'}
            </Button>
          </div>
        </div>
      )}
      {error && (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="h6" color="secondary">
          {successMessage}
        </Typography>
      )}
    </div>
  );
};

export default PlanDiscount;
