import React from 'react';
import { Container, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreSubscribe } from '../hooks';
import Loading from '../components/Loading';
import useCommonStyles from '../components/User/utils/UserCommonStyles';
import containerStyle from './containerStyle';

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
    addHandbookButton: {
      textDecoration: 'none',
      '& > button': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    table: {
      minHeight: '100%',
    },
    links: {
      margin: theme.spacing(3, 0),
    },
    tablePagination: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    subhead: {
      background: 'rgba(112, 112, 112, 0.25)',
    },
  };
});

const Plans = () => {
  const classes = useStyles();
  const sharedClasses = useCommonStyles();
  const [plans, isLoadingPlans] = useFirestoreSubscribe('plans');
  const [sites, isLoadingSites] = useFirestoreSubscribe('sites');

  const isLoading = isLoadingPlans || isLoadingSites;

  if (isLoading) {
    return <Loading />;
  }

  const enabledSitesCount = sites.reduce(
    (total, current) => {
      const scanSettings = current.userScanSettings.internal;
      return {
        free: total.free + Number(scanSettings.free.time > 0),
        trial: total.trial + Number(scanSettings.trial.time > 0),
        premium: total.premium + Number(scanSettings.premium.time > 0),
        pro: total.pro + Number(scanSettings.pro.time > 0),
        vip: total.vip + Number(scanSettings.vip.time > 0),
        executive: total.executive + Number(scanSettings.executive.time > 0),
      };
    },
    { free: 0, trial: 0, premium: 0, pro: 0, vip: 0, executive: 0 }
  );

  const headerRow = title => (
    <TableRow className={classes.subhead}>
      <TableCell>
        <strong>{title}</strong>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );

  const pricingRow = (title, cycle) => (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>$0.00</TableCell>
      <TableCell>$0.00</TableCell>
      <TableCell>${plans.find(plan => plan.cycle === cycle && plan.type === 'premium').price}</TableCell>
      <TableCell>${plans.find(plan => plan.cycle === cycle && plan.type === 'pro').price}</TableCell>
      <TableCell>${plans.find(plan => plan.cycle === cycle && plan.type === 'vip').price}</TableCell>
      <TableCell>
        $
        {plans
          .find(plan => plan.cycle === cycle && plan.type === 'executive')
          .price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </TableCell>
    </TableRow>
  );

  const discountRow = (title, cycle) => (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>0%</TableCell>
      <TableCell>0%</TableCell>
      <TableCell>{plans.find(plan => plan.cycle === cycle && plan.type === 'premium').discount}%</TableCell>
      <TableCell>{plans.find(plan => plan.cycle === cycle && plan.type === 'pro').discount}%</TableCell>
      <TableCell>{plans.find(plan => plan.cycle === cycle && plan.type === 'vip').discount}%</TableCell>
      <TableCell>{plans.find(plan => plan.cycle === cycle && plan.type === 'executive').discount}%</TableCell>
    </TableRow>
  );

  const getProtectionStat = (type, stat) => {
    const number = plans.find(plan => plan.type === type).protection[stat] || 0;
    if (number > 900) {
      return 'Unlimited';
    }
    return number;
  };

  const protectionRow = (title, stat) => (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>0</TableCell>
      <TableCell>{getProtectionStat('trial', stat)}</TableCell>
      <TableCell>{getProtectionStat('premium', stat)}</TableCell>
      <TableCell>{getProtectionStat('pro', stat)}</TableCell>
      <TableCell>{getProtectionStat('vip', stat)}</TableCell>
      <TableCell>{getProtectionStat('executive', stat)}</TableCell>
    </TableRow>
  );

  const hasFeature = (type, feature) => {
    if (Object.values(plans.find(plan => plan.type === type).features).find(f => f.id === feature)) {
      return 'Yes';
    }
    return '';
  };

  const getSupport = (type, support) => {
    return Object.values(plans.find(plan => plan.type === type).features).find(f => f.type === support).value;
  };

  const featureRow = (title, name) => (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{['privacyHandbook'].includes(name) ? 'Yes' : 'No'}</TableCell>
      <TableCell>{hasFeature('premium', name)}</TableCell>
      <TableCell>{hasFeature('premium', name)}</TableCell>
      <TableCell>{hasFeature('pro', name)}</TableCell>
      <TableCell>{hasFeature('vip', name)}</TableCell>
      <TableCell>{hasFeature('executive', name)}</TableCell>
    </TableRow>
  );

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle1">Admin</Typography>
      <Typography variant="h4">Membership Plans</Typography>
      <Typography variant="subtitle2">Manage the membership plan features and protection options</Typography>
      <div className={classes.links}>
        <a href="https://dashboard.stripe.com/subscriptions/products" target="_blank" rel="noopener noreferrer">
          View/Edit Plan Pricing in Stripe
        </a>
        <br />
        <a href="https://dashboard.stripe.com/coupons" target="_blank" rel="noopener noreferrer">
          View Coupons in Stripe
        </a>
        <br />
        <a href="https://dashboard.stripe.com/customers" target="_blank" rel="noopener noreferrer">
          View Customers in Stripe
        </a>
        <br />
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <Paper className={classes.paper} elevation={0}>
          <Table stickyHeader aria-label="sticky table" className={classes.table}>
            <TableHead className={sharedClasses.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Free (Canceled)</TableCell>
                <TableCell>Premium 7 Day Trial</TableCell>
                <TableCell>Premium</TableCell>
                <TableCell>Pro</TableCell>
                <TableCell>VIP</TableCell>
                <TableCell>Executive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {headerRow('Protection')}
              <TableRow>
                <TableCell>Sites Monitored</TableCell>
                <TableCell>{enabledSitesCount.free}</TableCell>
                <TableCell>{enabledSitesCount.trial}</TableCell>
                <TableCell>{enabledSitesCount.premium}</TableCell>
                <TableCell>{enabledSitesCount.pro}</TableCell>
                <TableCell>{enabledSitesCount.vip}</TableCell>
                <TableCell>{enabledSitesCount.executive}</TableCell>
              </TableRow>
              {protectionRow('Protected Aliases', 'aliases')}
              {protectionRow('Protected Addresses', 'addresses')}
              {protectionRow('Protected Phone Numbers', 'phoneNumbers')}
              {protectionRow('Protected Emails', 'emails')}
              {headerRow('Support')}
              <TableRow>
                <TableCell>Hours</TableCell>
                <TableCell>No</TableCell>
                <TableCell>{getSupport('premium', 'support')}</TableCell>
                <TableCell>{getSupport('premium', 'support')}</TableCell>
                <TableCell>{getSupport('pro', 'support')}</TableCell>
                <TableCell>{getSupport('vip', 'service')}</TableCell>
                <TableCell>{getSupport('executive', 'service')}</TableCell>
              </TableRow>
              {headerRow('Features')}
              {featureRow('Auto-Monitoring', 'constantProtection')}
              {featureRow('Auto-Removals', 'automaticRemovals')}
              {featureRow('Instant Alerts', 'instantAlerts')}
              {featureRow('Privacy Insights', 'privacyDashboard')}
              {featureRow('Privacy Handbook', 'privacyHandbook')}
              {headerRow('Pricing')}
              {pricingRow('Monthly', 'monthly')}
              {pricingRow('Annual', 'annual')}
              {discountRow('Annual Discount', 'annual')}
              {pricingRow('2 Year', 'biennial')}
              {discountRow('2 Year Discount', 'biennial')}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Container>
  );
};

export default Plans;
