import { db } from '../../../firebase';

export default async (id, hasRemoval, scanSettings) => {
  const internalSettings = Object.values(scanSettings);
  const enabledScans = internalSettings.find(atPlan => Boolean(atPlan.time));

  await db
    .collection('sites')
    .doc(id)
    .update({
      'userScanSettings.internal': scanSettings,
      ...(hasRemoval && { isRemoving: Boolean(enabledScans) }),
      isScanning: Boolean(enabledScans),
    });

  return true;
};
