import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useCommonStyles from '../utils/UserCommonStyles';
import ClientInformation from './ClientInformation';
import AdditionalInformation from './AdditionalInformation';

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: 300,
  },
  gridContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  gridWidth: {
    width: '100%',
  },
  gridInformation: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3),
    },
  },
  gridAdditionalInfo: {
    padding: theme.spacing(8, 2, 8, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const CardInformation = ({ id, user }) => {
  const { middleName, employer, dob } = user;
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  return (
    <Paper className={`${classes.paper} ${classesCommon.paper}`} elevation={0}>
      <Grid className={classes.gridContainer} container>
        <Grid className={classes.gridWidth} md={6} item>
          <div className={classes.gridInformation}>
            <ClientInformation id={id} user={user} />
          </div>
        </Grid>
        <Grid className={classes.gridWidth} md={6} item>
          <div className={classes.gridAdditionalInfo}>
            <AdditionalInformation middleName={middleName} employer={employer} dob={dob} id={id} />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardInformation;
