import React, { useState } from 'react';
import { Grid, Typography, Button, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon, Close as CloseIcon } from '@material-ui/icons';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CardInformation from './CardInformation';
import { useHistory } from 'react-router-dom';
import { functions } from '../../../firebase';
import { useConfirm } from 'material-ui-confirm';
import { db, auth } from '../../../firebase';
import useAsync from '../../../hooks/useAsync';

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(2.5),
    borderBottom: '1px solid #ececec',
  },
  buttonSection: {
    marginLeft: 'auto',
    marginBottom: 'auto',
    order: 2,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      order: 3,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'unset',
      '& > button:first-child': {
        marginLeft: 0,
      },
    },
  },
  button: {
    minWidth: 100,
    padding: theme.spacing(1.1, 2.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.primary.main,
  },
  buttonReset: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  headerName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    order: 1,
  },
  expandContainer: {
    width: '100%',
    order: 3,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  expandContent: {
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    paddingBottom: theme.spacing(2),
    marginTop: open => (open ? theme.spacing(3) : '-500px'),
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #ececed',
    },
  },
  inputField: {
    width: '100%',
    '& > div': {
      height: 50,
    },
  },
  messageStatus: {
    marginLeft: theme.spacing(2),
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const useStyledEditIcon = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.link}`,
    borderRadius: '35%',
    padding: theme.spacing(1.2, 1.4),
    cursor: 'pointer',
    fontSize: 17,
    '& > path': {
      fill: theme.palette.link,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `.3px solid ${theme.palette.secondary.main}`,
      '& > path': {
        fill: 'white',
      },
    },
  },
}));

const StyledEditIcon = ({ setOpen, open }) => {
  const classes = useStyledEditIcon();
  return open ? (
    <CloseIcon className={classes.root} onClick={setOpen} />
  ) : (
    <EditIcon className={classes.root} onClick={setOpen} />
  );
};

const updateNamesUser = async ({ firstName, lastName, id }) => {
  await db
    .collection('users')
    .doc(id)
    .update({ firstName, lastName });

  return true;
};

const HeaderInformation = ({ user, id }) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const classes = useStyles(open);
  const confirm = useConfirm();
  const [{ result, isLoading, error }, updateNames] = useAsync(updateNamesUser, false);

  const [{ isLoading: isBlocking, error: blockingError }, blockUser] = useAsync(async () => {
    await confirm({
      description: `This will ${user.isDisabled ? 'Unblock' : 'Block'} the user ${user.firstName +
        ' ' +
        user.lastName} from SafeShepherd.`,
    });
    await functions.httpsCallable('blockUser')({
      id,
      disabled: !user.isDisabled,
      stripeId: user.stripeId,
    });
  });

  const [{ isLoading: creatingAdmin, error: adminError }, grantAdminAccess] = useAsync(async () => {
    await confirm(
      user.isAdmin
        ? {
            description: `This will revoke admin permissions for user ${user.firstName +
              ' ' +
              user.lastName} on SafeShepherd.`,
          }
        : {
            description: `This will grant user ${user.firstName +
              ' ' +
              user.lastName} admin permissions on SafeShepherd.`,
          }
    );
    await db
      .collection('users')
      .doc(id)
      .update({ isAdmin: user.isAdmin ? false : true });
  });

  const [{ isLoading: isDeleting, error: deletingError }, deleteUser] = useAsync(async () => {
    await confirm({
      description: `This will permanently delete the user ${user.firstName + ' ' + user.lastName} from SafeShepherd.`,
    });
    await functions.httpsCallable('deleteUser')({ id });
    history.replace('/users');
  });

  const errorMessage = blockingError || deletingError || adminError;
  const history = useHistory();

  const INPUTS = {
    firstName: { label: 'First Name', value: firstName, onChange: e => setFirstName(e.target.value) },
    lastName: { label: 'Last Name', value: lastName, onChange: e => setLastName(e.target.value) },
  };

  return (
    <Grid className={classes.header} container alignItems="center">
      <Typography className={classes.headerName} variant="h4" color="primary">
        {user.firstName} {user.lastName}
        <StyledEditIcon setOpen={() => setOpen(!open)} open={open} />
        <Tooltip title={user.isAdmin ? 'Revoke Admin Access' : 'Grant Admin Access'}>
          <Button
            onClick={grantAdminAccess}
            color="primary"
            variant="text"
            disabled={id === auth().currentUser.uid ? true : false}
          >
            {creatingAdmin ? (
              <CircularProgress size={20} />
            ) : user.isAdmin ? (
              <SupervisorAccountIcon color="secondary" />
            ) : (
              <SupervisorAccountIcon />
            )}
          </Button>
        </Tooltip>
      </Typography>

      <div className={classes.buttonSection}>
        {user.stripeId && (
          <a
            href={`${process.env.REACT_APP_STRIPE_URL}/customers/${user.stripeId}`}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <Button className={`${classes.button} ${classes.buttonReset}`}>Stripe</Button>
          </a>
        )}

        <a
          href={`${process.env.REACT_APP_DASHBOARD_SITE_URL}/alerts?as=${id}`}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.link}
        >
          <Button className={`${classes.button} ${classes.buttonReset}`}>Vault</Button>
        </a>

        <Button
          data-testid="deleteUserButton"
          onClick={deleteUser}
          className={classes.button}
          variant="contained"
          color="secondary"
        >
          {isDeleting ? <CircularProgress size={20} /> : 'Delete'}
        </Button>
        <Button onClick={blockUser} className={`${classes.button} ${classes.buttonReset}`} variant="text">
          {isBlocking ? <CircularProgress size={20} /> : user.isDisabled ? 'Unblock' : 'Block'}
        </Button>
        {errorMessage && (
          <Typography className={classes.error} variant="h5" display="inline" color="error">
            Something went wrong.
          </Typography>
        )}
      </div>
      <div className={classes.expandContainer}>
        <div className={classes.expandContent}>
          <form
            onSubmit={e => {
              e.preventDefault();
              updateNames({ firstName, lastName, id });
            }}
          >
            <Grid container spacing={2}>
              {Object.values(INPUTS).map((input, i) => (
                <Grid key={i} item md={3} sm={12} xs={12}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    label={input.label}
                    defaultValue={input.value || ''}
                    onChange={input.onChange}
                    InputProps={{
                      required: true,
                    }}
                  />
                </Grid>
              ))}
              <Grid item md={6} sm={12} xs={12}>
                <Button type="submit" className={classes.button} variant="contained" color="secondary">
                  {isLoading ? <CircularProgress size={20} /> : 'Update'}
                </Button>
                {error && error.message ? (
                  <Typography className={classes.messageStatus} display="block" variant="subtitle2" color="error">
                    {error.message}
                  </Typography>
                ) : (
                  result && (
                    <Typography
                      className={classes.messageStatus}
                      display="inline"
                      variant="subtitle2"
                      color="secondary"
                    >
                      Information Updated.
                    </Typography>
                  )
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Grid>
  );
};

const ProfileInformation = ({ id, user }) => {
  return (
    <section>
      {[HeaderInformation, CardInformation].map((Component, i) => (
        <Component key={i} id={id} user={user} />
      ))}
    </section>
  );
};

export default ProfileInformation;
