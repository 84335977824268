// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { onAuthStateChange, auth, logout } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';
import { SSLogo } from '../images';

const useStyles = makeStyles(theme => ({
  logInContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    textAlign: 'center',
    '& > svg': {
      height: 150,
      width: 150,
      marginBottom: theme.spacing(2),
    },
  },
}));

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  credentialHelper: 'none',
  signInOptions: [{ provider: auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: false }],
};

const Login = () => {
  const [user, setUser] = useState({ loggedIn: false });
  const classes = useStyles();

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  if (!!user && user.loggedIn && !user.isAdmin) {
    return (
      <div className={classes.messageContainer}>
        <Typography variant="h6">Your account is not set as an admin. Please contact us or</Typography>
        <Button variant="outlined" color="primary" onClick={logout}>
          Logout
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.logInContainer}>
      <div className={classes.logoContainer}>
        <SSLogo />
      </div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
    </div>
  );
};

export default Login;
