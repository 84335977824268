import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { db } from '../../../firebase';
import { ALERT_TYPES } from '../../../alertTypes';

const useStyles = makeStyles(theme => ({
  dropDownButton: {
    minWidth: 100,
    height: '70%',
    margin: '0 auto',
    borderRadius: '20px',
    color: theme.palette.secondary.main,
    display: 'flex',
    '& > span:first-child': {
      paddingLeft: theme.spacing(1),
      '& > span': {
        marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1.6),
      },
    },
  },
  menu: {
    '& > div:nth-child(2)': {
      borderRadius: 30,
    },
    '& > div:nth-child(3)': {
      borderRadius: 30,
    },
  },
  menuItem: {
    width: 180,
    height: 50,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const saveStatus = async (type, alert) => {
  await db
    .collection('alerts')
    .doc(alert.id)
    .update({ type });
  return true;

  //todo: afterSave should update users alert status.
};

const StatusDropDown = ({ alert }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedAlert = ALERT_TYPES.find(at => at.id === alert.type);
  const selectedLabel = selectedAlert && selectedAlert.label;

  const closeMenu = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Button
      className={classes.dropDownButton}
      aria-haspopup="true"
      onClick={e => setAnchorEl(e.currentTarget)}
      endIcon={<ExpandMoreIcon />}
    >
      {selectedLabel || 'Edit'}
      <Menu className={classes.menu} anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        {ALERT_TYPES.map(alertType => (
          <MenuItem
            className={classes.menuItem}
            selected={alertType.id === alert.type}
            key={alertType.id}
            onClick={e => {
              saveStatus(alertType.id, alert);
              closeMenu(e);
            }}
          >
            {alertType.label}
          </MenuItem>
        ))}
      </Menu>
    </Button>
  );
};

export default StatusDropDown;
