import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create as CreateIcon } from '@material-ui/icons';

const useStyledCreateIcon = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: 8,
    top: 4,
    border: `1px solid ${theme.palette.link}`,
    borderRadius: '35%',
    padding: theme.spacing(1),
    cursor: 'pointer',
    zIndex: 100,
    fontSize: 17,
    '& > path': {
      fill: theme.palette.link,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `.3px solid ${theme.palette.secondary.main}`,
      '& > path': {
        fill: 'white',
      },
    },
  },
}));

const StyledCreateIcon = ({ onClick }) => {
  const classes = useStyledCreateIcon();
  return <CreateIcon onClick={onClick} className={classes.root} />;
};

export default StyledCreateIcon;
