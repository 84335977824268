import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import { useFirestoreSubscribe } from '../../../hooks';

import { ALERT_TYPES } from '../../../alertTypes';
import { functions } from '../../../firebase';

const useStylesBoxStats = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  count: {
    fontSize: '1.3rem',
  },
  label: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
}));

const BoxStats = ({ count, label }) => {
  const classes = useStylesBoxStats();

  return (
    <Grid item md={4} className={classes.root}>
      <Typography className={classes.count} color="primary" display="block" align="center">
        {count}
      </Typography>
      <Typography className={classes.label} color="primary" display="block" align="center">
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 15),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  button: {
    width: 120,
    padding: theme.spacing(1.4, 12.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
    margin: theme.spacing(1, 1.8),
    [theme.breakpoints.down('md')]: {
      width: 50,
    },
  },
  gridAlerts: {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  gridButton: {
    marginTop: theme.spacing(2),
  },
  secondaryBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Scans = ({ alerts = [], id }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [sites] = useFirestoreSubscribe('sites');

  const totals = ALERT_TYPES.reduce((totals, type) => {
    totals[type.id] = { ...type, count: 0 };
    return totals;
  }, {});

  for (const alert of alerts) {
    totals[alert.type].count = totals[alert.type].count + 1;
  }

  const [scanning, setScanning] = useState(false);
  const [scanningPromptOpen, setScanningPromptOpen] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);
  const [successAll, setSuccessAll] = useState(false);
  const [successSites, setSuccessSites] = useState(false);

  const scanAllSites = async () => {
    try {
      setSuccessAll(false);
      setSuccessSites(false);
      setScanning(true);
      await confirm({
        title: 'Scan All Sites',
        description: 'Are you sure you want to scan ALL enabled sites for this user?',
      });

      await functions.httpsCallable('triggerAllScans')({ id });
      setSuccessAll(true);
    } catch (e) {
      console.log('error scanning all sites', e);
    } finally {
      setScanning(false);
    }
  };

  const scanSites = async sites => {
    try {
      setSuccessAll(false);
      setSuccessSites(false);
      setScanning(true);
      const readableSites = sites.join(', ');
      await confirm({
        title: `Scan ${readableSites}`,
        description: `Are you sure you want to trigger scans for this user on ${readableSites}`,
      });
      setScanningPromptOpen(false);
      console.log('sending request');
      await functions.httpsCallable('triggerScans')({ id, sites });
      setSuccessSites(true);
    } catch (e) {
      console.log('error scanning all sites', e);
    } finally {
      setScanning(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.gridAlerts} container justify="space-between">
        {Object.values(totals).map(alert => (
          <BoxStats key={alert.label} count={alert.count} label={alert.label} />
        ))}
      </Grid>
      <Grid className={classes.gridButton} container justify="center">
        <Button
          className={classes.button}
          onClick={scanAllSites}
          disabled={scanning}
          variant="contained"
          color="secondary"
        >
          {successAll && <Check />}
          {scanning ? 'Scanning...' : 'Re-scan'}
        </Button>
        <Button
          className={`${classes.secondaryBtn} ${classes.button}`}
          disabled={scanning}
          variant="text"
          color="primary"
          onClick={() => setScanningPromptOpen(true)}
        >
          {scanning && <CircularProgress />}
          {successSites && <Check />}
          Scan by Site
        </Button>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={scanningPromptOpen}
          onClose={() => setScanningPromptOpen(false)}
        >
          <DialogTitle>Select which sites to scan</DialogTitle>
          <DialogContent>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              value={selectedSites}
              multiple
              renderValue={selected => selected.join(', ')}
              onChange={e => setSelectedSites(e.target.value)}
            >
              {sites.map(site => (
                <MenuItem value={site.id} key={site.id}>
                  <Checkbox checked={selectedSites.includes(site.id)} />
                  {site.label}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setScanningPromptOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => scanSites(selectedSites)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default Scans;
