import React from 'react';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import PropertyEditorField from './PropertyEditorField';
import { InputAdd } from '../../../images'; //Done

const formatLabel = fieldName => {
  return (
    // assuming `key` is "camelCase"...
    fieldName
      // insert a space before all caps ("camel Case")
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character ("Camel Case")
      .replace(/^./, function(str) {
        return str.toUpperCase();
      })
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h6': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  addButton: {
    padding: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

export default ({ fieldName, values = [], saveValues, isAddress, isPhoneNumber, isAlias }) => {
  const classes = useStyles();

  const defaultNewValue = isAddress
    ? {
        street: '',
        apt: '',
        city: '',
        state: '',
        zip: '',
      }
    : '';

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">Protected {formatLabel(fieldName)}</Typography>
        <IconButton
          className={classes.addButton}
          onClick={() => {
            saveValues([...values, defaultNewValue]);
          }}
        >
          <InputAdd />
        </IconButton>
      </div>
      {values.map((value, index) => (
        <PropertyEditorField
          key={fieldName + index}
          value={value}
          isAlias={isAlias}
          isAddress={isAddress}
          isPhoneNumber={isPhoneNumber}
          saveValues={newVal => {
            saveValues([...values.slice(0, index), newVal, ...values.slice(index + 1)]);
          }}
          onDelete={() => {
            saveValues(values.slice(0, index).concat(values.slice(index + 1)));
          }}
        />
      ))}
    </>
  );
};
