import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import userStylesCommon from '../utils/UserCommonStyles';
import Scans from './Scans';

import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  lastScan: {
    marginLeft: 'auto',
    '& > span:nth-child(1)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(2, 0, 0),
    },
  },
  bold500: {
    fontWeight: 500,
  },
  bold600: {
    fontWeight: 600,
  },
  textLight: {
    color: theme.palette.link,
  },
  paper: {
    minHeight: 300,
  },
}));

const Analytics = ({ alerts = [], id }) => {
  const classes = useStyles();
  const classesCommon = userStylesCommon();

  const lastScan = alerts.sort((a, b) => a.createdAt < b.createdAt)[0];
  const lastScanDate = lastScan ? format(lastScan.createdAt, 'MMM dd yyyy') : 'N/A';
  return (
    <section>
      <Grid container alignItems="center">
        <Typography className={classes.bold500} variant="h4" display="inline" color="primary">
          Analytics
        </Typography>
        <Typography className={classes.lastScan} color="primary" variant="body1" display="inline">
          <span className={classes.bold600}>Last Scan:</span>
          <span className={classes.textLight}>{lastScanDate}</span>
        </Typography>
      </Grid>

      <Paper elevation={0} className={`${classesCommon.paper} ${classes.paper}`}>
        <Scans alerts={alerts} id={id} />
      </Paper>
    </section>
  );
};

export default Analytics;
