import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { auth } from '../../firebase';
import { Tooltip, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  Person as UserIcon,
  Settings as SettingsIcon,
  ArrowBack as CollapseIcon,
  ArrowForward as ExpandIcon,
  MenuBook as HandbookIcon,
  Receipt as ScanLogsIcon,
  PowerSettingsNewRounded as SignOutIcon,
  Timeline as AnalyticsIcon,
  HelpOutline as FAQIcon,
  Code as APIIcon,
  ShoppingCart as PlansIcon,
} from '@material-ui/icons';

import { SSLogo } from '../../images';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(11) - 1,
  },
  list: {
    '& .MuiListItem-root': {
      margin: `${theme.spacing(2)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
  subheader: {
    paddingLeft: theme.spacing(4),
    lineHeight: 1,
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '$drawerClose &': {
      opacity: 0,
    },
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    '& svg': {
      color: theme.palette.link,
    },
    '&.active': {
      color: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
  },
  logo: {
    display: 'block',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 80,
    width: 85,
    height: 85,
  },
}));

export default () => {
  const classes = useStyles();
  const [open, setDrawerOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getNavLink = ({ path, icon, label }) => {
    return (
      <NavLink to={path} className={classes.navLink} activeClassName="active">
        <ListItem button>
          <Tooltip title={label}>
            <ListItemIcon>{icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={label} />
        </ListItem>
      </NavLink>
    );
  };

  return (
    <Drawer
      className={classnames({
        [classes.drawer]: true,
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="persistent"
      anchor="left"
      open={true} //
      classes={{
        paper: classnames({
          [classes.drawerPaper]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {/** Top List */}
      <List className={classes.list}>
        <SSLogo className={classes.logo} />
        {getNavLink({ path: '/users', label: 'Users', icon: <UserIcon /> })}
        {getNavLink({ path: '/site-settings', label: 'Site Settings', icon: <SettingsIcon /> })}
        {getNavLink({ path: '/usage', label: 'Analytics', icon: <AnalyticsIcon /> })}
        {getNavLink({ path: '/scan-logs', label: 'Scan Logs', icon: <ScanLogsIcon /> })}
        {getNavLink({ path: '/plans', label: 'Plans', icon: <PlansIcon /> })}
        {getNavLink({ path: '/handbook', label: 'Handbook', icon: <HandbookIcon /> })}
        {getNavLink({ path: '/faq', label: 'FAQs', icon: <FAQIcon /> })}
        {getNavLink({ path: '/api', label: 'API', icon: <APIIcon /> })}
      </List>

      {/** Bottom List */}
      <List className={classes.list}>
        <ListItem button onClick={() => auth().signOut()}>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
        <Divider />
        <ListItem button onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <ListItemIcon>{open ? <CollapseIcon /> : <ExpandIcon />}</ListItemIcon>
          <ListItemText primary="Collapse" />
        </ListItem>
      </List>
    </Drawer>
  );
};
