import React from 'react';
import { Typography, Grid, Paper, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useStylesCommon from '../components/User/utils/UserCommonStyles';
import Loading from '../components/Loading';
import { useFirestoreSubscribe } from '../hooks';
import columnsConfig from '../components/User/Alerts/AlertsTableColumnsConfig';
import ItemsTable from '../components/ItemsTable';
import { useMediaQuery } from '@material-ui/core';
import containerStyle from './containerStyle';

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
    totalAlerts: {
      marginLeft: 'auto',
      paddingRight: theme.spacing(1),
      '& > span:nth-child(1)': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: `${theme.spacing(2)}px 0 0 0`,
      },
    },
    bold500: {
      fontWeight: 500,
    },
    bold600: {
      fontWeight: 600,
    },
    textLight: {
      color: theme.palette.link,
    },
    paper: {
      minHeight: 400,
      '& .MuiPaper-root': {
        '& .MuiToolbar-root': {
          '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
              margin: theme.spacing(0.6),
            },
          },
        },
      },
    },
  };
});

const ScanLogs = () => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();
  const [rawAlerts, isAlertsLoading] = useFirestoreSubscribe('alerts', null, 'createdAt', 1000);
  const [users, isUsersLoading] = useFirestoreSubscribe('users');
  const alerts = rawAlerts.map(alert => {
    return { ...alert, user: users.find(u => u.id === alert.userId) };
  });

  const isLoading = isAlertsLoading || isUsersLoading;
  const isVisibleOnMobile = useMediaQuery('(max-width: 960px)');

  return (
    <Container className={classes.container} maxWidth={false}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="subtitle1">Admin</Typography>
          <Typography variant="h4">Scan Logs</Typography>
          <Typography variant="subtitle2">View the status of all site scans </Typography>
          <section>
            <Grid container>
              <Typography className={classes.totalAlerts} color="primary" variant="body1" display="inline">
                <span className={classes.bold600}>Total Scans:</span>
                <span className={classes.textLight}>{alerts.length}</span>
              </Typography>
            </Grid>
            <Paper className={`${classes.paper} ${classesCommon.paper}`} elevation={0}>
              <ItemsTable title="Scans" columns={columnsConfig(alerts, isVisibleOnMobile)} items={alerts} />
            </Paper>
          </section>
        </>
      )}
    </Container>
  );
};

export default ScanLogs;
