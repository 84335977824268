import React, { useState } from 'react';
import { Typography, Button, MenuItem, Menu, CircularProgress } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { functions } from '../../../firebase';
import useAsync from '../../../hooks/useAsync';
import { useConfirm } from 'material-ui-confirm';
import { useFirestoreSubscribe } from '../../../hooks';
import Loading from '../../Loading';

const useStylesChangePlan = makeStyles(theme => ({
  bold: {
    fontWeight: 500,
  },
  dropDownButton: {
    width: '100%',
    height: 50,
    margin: theme.spacing(2, 0, 0, 0),
    border: `1px solid ${theme.palette.link}`,
    color: theme.palette.link,
    display: 'flex',
    justifyContent: 'flex-start',
    '& > span:first-child': {
      paddingLeft: theme.spacing(1),
      '& > span': {
        marginLeft: 'auto',
        paddingRight: theme.spacing(1.6),
      },
    },
  },
  menuContainer: {
    '& .MuiMenu-paper': {
      width: 364,
    },
  },
  confirmChangesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
}));

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const ChangePlan = ({ userPlan, id }) => {
  const [selectedPlan, setSelectedPlan] = useState(userPlan);
  const confirm = useConfirm();
  const classes = useStylesChangePlan();
  const [unsortedPlans, isLoadingPlans] = useFirestoreSubscribe('plans');

  const getPlanName = plan => {
    if (plan.type === 'free') {
      return 'Free';
    }

    if (plan.type === 'trial') {
      return 'Trial';
    }
    return titleCase(`${plan.type} ${plan.cycle} - $${plan.price}`);
  };

  const [{ isLoading, error }, changeUserPlan] = useAsync(async (id, plan) => {
    await confirm({
      title: 'Are you sure?',
      description: `Please confirm you are changing the plan to ${getPlanName(
        plan
      )}. This will trigger an email to the User and update their subscription in Stripe.`,
    });
   
    await functions.httpsCallable('adminChangeSubscription')({ planId: plan.id, userId: id });
  });
  const [anchorEl, setAnchorEl] = useState(null);

  if (isLoadingPlans) {
    return <Loading />;
  }

  const orderedTypes = ['free', 'trial', 'premium', 'pro', 'vip', 'executive'];
  const plans = unsortedPlans.sort((a, b) => {
    const sortValue = orderedTypes.indexOf(a.type) - orderedTypes.indexOf(b.type);

    if (sortValue !== 0) {
      return sortValue;
    }
    return a.price - b.price;
  });

  return (
    <>
      <div className={classes.sectionPlanDiscount}>
        <Typography className={classes.bold} color="primary" variant="body1" display="block">
          Adjust Plan
        </Typography>

        <Button
          className={classes.dropDownButton}
          aria-controls="plan-discount"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
          endIcon={<ExpandMoreIcon />}
        >
          {getPlanName(selectedPlan)}
        </Button>
        <Menu
          id="plan-discount"
          className={classes.menuContainer}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {plans.map(plan => {
            return (
              <MenuItem
                key={plan.id}
                className={classes.capitalize}
                onClick={() => {
                  setSelectedPlan(plan);
                  setAnchorEl(null);
                }}
              >
                {getPlanName(plan)}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      {(userPlan.id !== selectedPlan.id || isLoading) && (
        <div className={classes.confirmChangesContainer}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                changeUserPlan(id, selectedPlan);
              }}
            >
              {isLoading ? <CircularProgress className={classes.loading} size={21} /> : 'Save Changes'}
            </Button>
            {error && (
              <Typography variant="h6" color="error">
                Something went wrong.
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePlan;
