import React from 'react';
import { Container, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import containerStyle from './containerStyle';
import Editor from 'rich-markdown-editor';

const url = process.env.REACT_APP_API_URL;

const docs = `
## Authentication
Most requests in the api require authentication. The major exception is the signup method, which can be used by anyone and is used by the frontend when creating users.

### Obtaining a session token:
POST ${url}/login
\`\`\`javascript
{
	"data": {
		"email": "matt+test@opticpower.com",
		"password": "testing123"
	}
}
\`\`\`

This will return you with an idToken that you can use for future requests. This token must be used as a Bearer token. 

\`\`\`javascript
{
  "result": {
    "kind": "identitytoolkit#VerifyCustomTokenResponse",
    "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImZjMmM4YmIyNmE3OGM0M2JkODYzNzA1YjNkNzkyMWI0ZTY0MjVkNTQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2FmZXNoZXBoZXJkLWRldiIsImF1ZCI6InNhZmVzaGVwaGVyZC1kZXYiLCJhdXRoX3RpbWUiOjE1ODkyMzYwMzAsInVzZXJfaWQiOiJWeUhYVVBGYmFmZ1NqWEZLa3k2eVJQQUhBWnMxIiwic3ViIjoiVnlIWFVQRmJhZmdTalhGS2t5NnlSUEFIQVpzMSIsImlhdCI6MTU4OTIzNjAzMCwiZXhwIjoxNTg5MjM5NjMwLCJlbWFpbCI6Im1hdHQrdGVzdEBvcHRpY3Bvd2VyLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbIm1hdHQrdGVzdEBvcHRpY3Bvd2VyLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.1yeNszTWElN_pS917LTIUbDdBOiY72DfuJQq3F9hYnu5TuxYjhVOIJLMpOOU4yEzvvSOKKSfCxQkvzJmF2sML4rZYoLlJl18czypNNldCVmy785XW015t6HiALY95BnRZrcs39k7pX9PRBcG6zGcMnKxOkSq-RNnqD7QU1wHyn2Rs9-b0CU0-lEWWzkcH4ZvzbyQs4OYER_O5i4kTSifNNjPj5tHP0a4FWArzc7PjjDx1inHbq2bvwKdPIy6BBh_s3p3ry4eXbM2_zgLGbein01DjcMvAXHfrE7vBJJrisUMQ6gkrxSJ5nF9uBo-FBZHqWZ0WzTMvzcRVTZs06IWSg",
    "refreshToken": "AE0u-NcQxxywhIOTgnDyT-NzJ9HqvNcDP1JXrWAiVmSGr45qmP7ywZHG-sI6w4VwOJrZsmSxmtZ2evVdRlMjoFR6c3LM4dXWe_UXekm0cAAnHaC0ykEGteZjPhejJqWYZbWwJla7_CfoY-ZOgEmvO--EY-DuaGQTNxIDwdm6yRlTELCmPyDjbyJYBWeqOlaCPYMHV10HdF93",
    "expiresIn": "3600",
    "isNewUser": false
  }
}
\`\`\`


## User Management

### Creating a user
POST ${url}/users/new



### Getting a users information
You can get any users information by sending a request to the users get endpoint.

POST ${url}/users/get
\`\`\`javsacript
{
	"data": {
		"id": "5aa8UNZC8v4gLn7mbzqr"
	}
}
\`\`\`

### Getting all users
POST ${url}/users/all

### Getting a users alerts
POST ${url}/users/alerts
\`\`\`javascript
{
	"data": {
		"id": "5aa8UNZC8v4gLn7mbzqr"
	}
}
\`\`\`

### Updating a users e-mail address or password
POST ${url}/users/update
\`\`\`javascript
{
	"data": {
    "id": "5aa8UNZC8v4gLn7mbzqr"
    "email": "this.is.my.new@email.com"
	}
}
\`\`\`

### Blocking a user
You can block or unblock a user by triggering the block endpoint with the block command.

POST ${url}/users/block
\`\`\`javsacript
{
	"data": {
    "id": "5aa8UNZC8v4gLn7mbzqr",
    "disabled": true,
	}
}
\`\`\`

### Deleting a user
POST ${url}/users/delete
\`\`\`javascript
{
	"data": {
		"id": "5aa8UNZC8v4gLn7mbzqr"
	}
}
\`\`\`

## Scanning and removing

### Scanning all enabled sites on a user
POST ${url}/scans/all
\`\`\`javascript
{
	"data": {
		"id": "5aa8UNZC8v4gLn7mbzqr"
	}
}
\`\`\`

### Scanning some sites on a user (includes disabled sites)
POST ${url}/scans/some
\`\`\`javascript
{
	"data": {
    "id": "5aa8UNZC8v4gLn7mbzqr",
    "sites": [
      "411locate", 
      "addresses"
    ]
	}
}
\`\`\`

### Schedule a remover for a user
POST ${url}/removers
\`\`\`javascript
{
	"data": {
    "id": "5aa8UNZC8v4gLn7mbzqr",
    "alertId": "KFAlzMcbFAG0tXOFqYmy",
    "siteId": "411locate"
}
\`\`\`
`;

const useStyles = makeStyles(theme => {
  return {
    ...containerStyle(theme),
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '80%',
    },
  };
});

const FAQ = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle1">Admin</Typography>
      <Typography variant="h4">API Documentation</Typography>
      <Typography variant="subtitle2">Programmatically manage safe shepherd</Typography>
      <Paper elevation={0} className={classes.paper}>
        <Editor defaultValue={docs} readOnly />
      </Paper>
    </Container>
  );
};

export default FAQ;
