import React from 'react';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useStylesCommon from '../utils/UserCommonStyles';
import StripeSection from './Stripe';
import ChangePlanSection from './ChangePlan';
import PlanDiscountSection from './PlanDiscount';
import format from 'date-fns/format';
import useAsync from '../../../hooks/useAsync';
import { functions } from '../../../firebase';

const useStyles = makeStyles(theme => ({
  memberSince: {
    marginLeft: 'auto',
    '& > span:nth-child(1)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: `${theme.spacing(2)}px 0 0 0`,
    },
  },
  textLight: {
    color: theme.palette.link,
  },
  paper: {
    minHeight: 400,
  },
  grid: {
    width: '100%',
    padding: theme.spacing(5, 5.8),
  },
  bold500: {
    fontWeight: 500,
  },
  bold600: {
    fontWeight: 600,
  },
  changePlan: {
    width: '100%',
    marginRight: theme.spacing(5.8),
    marginLeft: theme.spacing(5.8),
    paddingBottom: theme.spacing(1.6),
    borderBottom: `1px solid #ececec`,
  },
  link: {
    color: theme.palette.link,
    textTransform: 'capitalize',
  },
}));

const Membership = ({ id, user }) => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();

  const [{ isLoading }, adminChangeSubscription] = useAsync(async event => {
    event.preventDefault();
    await functions.httpsCallable('adminChangeSubscription')({ planId: 'free', userId: id });
  });

  const endsAt = user.plan.endsAt || user.plan.trialEnds;

  return (
    <section>
      <Grid container alignItems="center">
        <Typography className={classes.bold500} variant="h4" display="inline" color="primary">
          Membership
        </Typography>
        <Typography className={classes.memberSince} color="primary" variant="body1" display="inline">
          <span className={classes.bold600}>Member Since:</span>
          <span className={classes.textLight}>{format(user.createdAt, 'LLL d y')}</span>
        </Typography>
      </Grid>
      <Paper className={`${classes.paper} ${classesCommon.paper}`} elevation={0}>
        <Grid container spacing={0}>
          <Grid className={classes.grid} item md={12}>
            <StripeSection stripeId={user.stripeId} />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <div className={classes.changePlan}>
            <Typography className={classes.bold500} variant="body1" display="block" color="primary">
              Current Plan
            </Typography>
            <Typography variant="body1" className={classes.link}>
              {user.plan.cycle ? `${user.plan.type} ${user.plan.cycle}` : endsAt ? 'Trial' : 'Free Plan'}
            </Typography>
            {endsAt ? (
              <Typography variant="body1" className={classes.link}>
                Ending at {format(endsAt.toDate(), 'LLL d y')}
              </Typography>
            ) : (
              user.plan.id !== 'free' && (
                <Button variant="outlined" onClick={adminChangeSubscription} disabled={isLoading}>
                  Cancel Subscription
                </Button>
              )
            )}
          </div>
        </Grid>
        <Grid container spacing={0}>
          <Grid className={classes.grid} item md={6}>
            <ChangePlanSection userPlan={user.plan} id={id} />
          </Grid>
          <Grid className={classes.grid} item md={6}>
            <PlanDiscountSection id={id} />
          </Grid>
        </Grid>
      </Paper>
    </section>
  );
};

export default Membership;
