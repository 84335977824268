import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';

const rootStyling = theme => ({
  border: `1px solid ${theme.palette.link}`,
  borderRadius: '35%',
  padding: theme.spacing(1.4, 1.6),
  cursor: 'pointer',
  fontSize: 17,
  '& > path': {
    fill: theme.palette.link,
  },
});

const useStyledIcon = makeStyles(theme => ({
  create: {
    ...rootStyling(theme),
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `.3px solid ${theme.palette.secondary.main}`,
      '& > path': {
        fill: 'white',
      },
    },
  },
  delete: {
    ...rootStyling(theme),
    '&:hover': {
      background: 'red !important',
      border: `.3px solid red !important`,
      '& > path': {
        fill: 'white',
      },
    },
  },
}));

export const StyledCreateIcon = () => {
  const classes = useStyledIcon();
  return <CreateIcon className={classes.create} />;
};

export const StyledDeleteIcon = props => {
  const classes = useStyledIcon();
  return <DeleteIcon className={classes.delete} {...props} />;
};
