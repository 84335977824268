import SiteSettings from './SiteSettings';
import Users from './Users';
import User from './User';
import Handbook from './Handbook';
import AddEditHandbook from './AddEditHandbook';
import AddEditFAQ from './AddEditFAQ';
import FAQ from './FAQ';
import API from './API';
import ScanLogs from './ScanLogs';
import Analytics from './Analytics';
import Plans from './Plans';

export default {
  '/site-settings': SiteSettings,
  '/usage': Analytics,
  '/scan-logs': ScanLogs,
  '/users/:id': User,
  '/users': Users,
  '/handbook/:id': AddEditHandbook,
  '/handbook': Handbook,
  '/faq/:id': AddEditFAQ,
  '/faq': FAQ,
  '/api': API,
  '/plans': Plans,
};
