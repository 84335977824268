import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ItemsTable from '../../ItemsTable';
import columnsConfig from './AlertsTableColumnsConfig';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(5),
  },
  totalAlerts: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(1),
    '& > span:nth-child(1)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: `${theme.spacing(2)}px 0 0 0`,
    },
  },
  bold500: {
    fontWeight: 500,
  },
  bold600: {
    fontWeight: 600,
  },
  textLight: {
    color: theme.palette.link,
  },
  paper: {
    minHeight: 400,
  },
}));

const Alerts = ({ alerts = [], user }) => {
  const classes = useStyles();
  const isVisibleOnMobile = useMediaQuery('(max-width: 960px)');

  return (
    <section>
      <Grid className={classes.headerContainer} container alignItems="center">
        <Typography className={classes.bold500} variant="h4" display="inline" color="primary">
          Scan Logs
        </Typography>
        <Typography className={classes.totalAlerts} color="primary" variant="body1" display="inline">
          <span className={classes.bold600}>Total Alerts:</span>
          <span className={classes.textLight}>{alerts.length}</span>
        </Typography>
      </Grid>
      <ItemsTable
        columns={columnsConfig(alerts, isVisibleOnMobile).filter(
          column => column.name !== 'user.firstName' && column.name !== 'user.lastName'
        )}
        items={alerts}
      />
    </section>
  );
};

export default Alerts;
