import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button, CircularProgress } from '@material-ui/core';
import SiteSettingsDropDown from './SiteSettingsDropdown';
import TimeScan from './TimeScanInput';
import { useAsync } from '../../hooks';
import saveSettings from '../SiteSettingsPanel/utils/saveSettings';

const useStyles = makeStyles(theme => ({
  container: {
    '& > h5': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    marginBottom: theme.spacing(2),
  },
  paper: {
    border: `1px solid ${theme.palette.link}`,
    borderRadius: '30px',
    marginBottom: theme.spacing(4),
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
    },
  },
  scanSetting: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      paddingLeft: theme.spacing(1.5),
    },
    '&:nth-child(odd)': {
      background: '#F8F8F8',
      borderRadius: 20,
    },
  },
  firstChild: {
    width: '40%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 55,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  secondChild: {
    minWidth: '50%',
    paddingLeft: 55,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  boldText: {
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    zIndex: 100,
  },
  alignSettingText: {
    marginLeft: theme.spacing(3.8),
    marginRight: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > button': {
      color: 'white',
      background: '#26C4C4',
      '&:hover': {
        background: '#26C4C4',
      },
    },
  },
}));

export default ({ site }) => {
  const classes = useStyles();
  const [scanSettings, setScanSettings] = useState(
    Boolean(site) && site.userScanSettings && site.userScanSettings.internal
  );
  const [{ result: isSaveSuccessful, isLoading: isSaving, error }, handleSave] = useAsync(() =>
    saveSettings(site.id, site.hasRemoval, scanSettings)
  );

  return (
    <div className={classes.container}>
      <Typography variant="h5" color="primary">
        Plan Scan Settings
      </Typography>

      <Paper className={classes.paper} elevation={0}>
        <div className={classes.darkColumn}></div>

        {['Free', 'Trial', 'Premium', 'Pro', 'VIP', 'Executive'].map(name => {
          const id = name.toLowerCase();
          const settings = scanSettings[id];
          return (
            <div key={name} className={classes.scanSetting}>
              <div className={classes.firstChild}>
                <Typography className={classes.boldText} variant="body1" color="primary">
                  {name}
                </Typography>
              </div>
              <div className={classes.secondChild}>
                <TimeScan
                  setSelectedScanTimes={time =>
                    setScanSettings({ ...scanSettings, [id]: { ...scanSettings[id], time } })
                  }
                  selectedScanTimes={settings.time}
                />
                <Typography className={classes.boldText} variant="body1" color="primary">
                  Scans per
                </Typography>
                <SiteSettingsDropDown
                  setSelectedSubscription={every =>
                    setScanSettings({ ...scanSettings, [id]: { ...scanSettings[id], every } })
                  }
                  selectedSubscription={settings.every}
                />
              </div>
            </div>
          );
        })}
      </Paper>

      <div className={classes.buttonContainer}>
        <Button
          id="saveSettings"
          variant="contained"
          disabled={isSaving ? true : false}
          onClick={() => {
            handleSave(site.id, scanSettings);
          }}
        >
          Save Scan Settings
        </Button>
        {isSaving && <CircularProgress size={21} />}
        {isSaveSuccessful === true && (
          <Typography variant="body1" color="secondary">
            Scan Settings Updated.
          </Typography>
        )}
        {error && (
          <Typography variant="body1" color="error">
            Something went wrong.
          </Typography>
        )}
      </div>
    </div>
  );
};
