import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  downArrow: {
    display: 'flex',
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  //Menu paper Styles
  menuContainer: {
    '& .MuiMenu-paper': {
      borderRadius: 2,
      width: 100,
      '& .MuiMenu-list': {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        '& .MuiMenuItem-root': {
          minHeight: 'unset',
          padding: theme.spacing(1.5),
          borderBottom: `1px solid ${theme.palette.link}`,
          fontWeight: 400,
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  settingButton: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-label': {
      width: '100px',
      [theme.breakpoints.down('md')]: {
        width: 'unset',
      },
    },
  },
}));

export default ({ setSelectedSubscription, selectedSubscription }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const TimeFrames = {
    7: 'Week',
    90: 'Quarter',
    30: 'Month',
    365: 'Year',
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.settingButton}
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {TimeFrames[selectedSubscription]}
        <span className={classes.downArrow}>
          <DownArrow fontSize="large" />
        </span>
      </Button>
      <Menu
        className={classes.menuContainer}
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {Object.keys(TimeFrames).map(key => {
          return (
            <MenuItem
              key={key}
              onClick={() => {
                setSelectedSubscription(+key);
                setAnchorEl(null);
              }}
            >
              {TimeFrames[key]}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
