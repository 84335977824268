import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Nav from './components/Nav';
import screens from './screens';
import { ConfirmProvider } from 'material-ui-confirm';
import Login from './screens/Login';
import { onAuthStateChange, login } from './firebase';
import Loading from './components/Loading';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[50],

    // Make sure site is at least 100vh so footer's always at the bottom.
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',

    // Make sure when BottomNav is showing dosen't hide the childrens components.
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(7),
    },
  },
  mainContent: {
    flexGrow: 1,
  },
}));

function App() {
  const classes = useStyles();
  const [user, setUser] = useState({ loggedIn: false, initializing: true });

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  if (user.initializing) {
    return <Loading />;
  }

  return (
    <div
      className={classnames({
        [classes.mainContent]: true,
      })}
    >
      <Helmet>{process.env.REACT_APP_ENVIRONMENT !== 'production' && <meta name="robots" content="noindex" />}</Helmet>
      <ConfirmProvider>
        <Router>
          <div className={classes.root}>
            {!user.loggedIn || !user.isAdmin ? (
              <Login onClick={login} user={user} />
            ) : (
              <>
                <Nav />
                <Switch>
                  {Object.keys(screens).map(path => (
                    <Route path={path} exact component={screens[path]} key={path}></Route>
                  ))}
                  <Route path="/" exact>
                    <Redirect to="/users" />
                  </Route>
                </Switch>
              </>
            )}
          </div>
        </Router>
      </ConfirmProvider>
    </div>
  );
}

export default App;
