import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { Person as UserIcon, Timeline as AnalyticsIcon, Receipt as ScanLogsIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100vw',
    bottom: 0,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    zIndex: 1,
  },
  navLink: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    textDecoration: 'none',
    color: theme.palette.link,
    '&.active': {
      '& .MuiBottomNavigationAction-label': {
        color: theme.palette.primary.main,
      },
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));

export default () => {
  const classes = useStyles();

  // Implememnted our own bottom nav because MaterialUI's bottom-nav for some reason only shows on scroll on mobile.
  return (
    <BottomNavigation className={classes.root}>
      <NavLink to="/users" className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="Users" icon={<UserIcon />} />
      </NavLink>
      <NavLink to="/usage" className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="Analytics" icon={<AnalyticsIcon />} />
      </NavLink>
      <NavLink to="/scan-logs" className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="Scan Logs" icon={<ScanLogsIcon />} />
      </NavLink>
    </BottomNavigation>
  );
};
