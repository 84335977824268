import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UsersTable from '../components/Users/UsersTable';
import containerStyle from './containerStyle';

const useStyles = makeStyles(containerStyle);

const Users = ({ location }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant="subtitle1">Admin</Typography>
      <Typography variant="h4">All Users</Typography>
      <Typography variant="subtitle2">View and manage all site users</Typography>

      <UsersTable />
    </Container>
  );
};

export default Users;
