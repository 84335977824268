import React, { useState, useEffect } from 'react';
import {
  Container,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import Editor from 'rich-markdown-editor';
import { db } from '../firebase';

import { useFirestoreSubscribeDoc, useFirestoreSubscribe } from '../hooks';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    '& > section': {
      marginTop: theme.spacing(10),
      '&:nth-child(2)': {
        marginTop: theme.spacing(2),
      },
    },
  },
  linkAllUsers: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.link,
    transition: 'color 100ms ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '& > svg': {
      fontSize: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
    },
  },
  loading: {
    marginTop: theme.spacing(5),
    height: '100%',
  },
  contentContainer: {
    boxShadow: `0px 1px 5px #f5f5f5`,
  },
  editor: {
    border: '1px solid #ccc',
    borderRadius: 20,
    width: '90%',
    padding: theme.spacing(2.5, 5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  button: {
    display: 'grid',
    minWidth: 100,
    padding: theme.spacing(1.1, 2.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 12,
    margin: `auto 0 0`,
  },
  formControl: {
    marginBottom: theme.spacing(1.1),
    marginTop: theme.spacing(1.1),
  },
  textField: {
    marginBottom: theme.spacing(1.1),
    marginTop: theme.spacing(1.1),
    '& > div': {
      '& > input': {
        color: theme.palette.link,
        '&:focus': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& > label': {
      fontSize: 15,
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > *:first-child': {
      marginBottom: theme.spacing(2),
    },
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      margin: 'auto',
      width: 150,
      marginBottom: theme.spacing(1.5),
    },
    '& .MuiFormLabel-root': {
      margin: theme.spacing(0, 1, 1, 1),
      color: theme.palette.secondary.main,
      fontSize: '1.125rem',
      fontWeight: 450,
    },
  },
}));

/**
 * Formats article name as valid doc id in the handbook collection in firebase (letters/nums only, less than 32 char).
 * This is also used as the unique url in the privacy handbook.
 * i.e. `New Content Article` would become -> `new_content_article` and -> `/handbook/new_content_article`
 */

const formatDocId = name => {
  const formatId = name
    ?.replace(/[^\w\s]/gi, '')
    .toLowerCase()
    .replace(/ /g, '_')
    .substr(0, 32);

  return formatId?.substr(0, Math.min(formatId.length, formatId.lastIndexOf('_')));
};

const Handbook = ({
  location,
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const [result, isLoadingHandbook] = useFirestoreSubscribeDoc('handbooks', id);
  const [sites, isLoadingSites] = useFirestoreSubscribe('sites');
  const [updatedName, setUpdatedName] = useState(result.name);
  const [updatedContent, setUpdatedContent] = useState(result.content);
  const [siteIdentifier, setSiteIdentifier] = useState(result.id);
  const [type, setType] = useState(result.type);
  const [optOutUrl, setUpdatedOptOutUrl] = useState(result.optOutUrl);
  const [privacyPolicyUrl, setUpdatedPrivacyPolicyUrl] = useState(result.privacyPolicyUrl);
  const [siteUrl, setUpdatedSiteUrl] = useState(result.siteUrl);
  const [articleTitle, setUpdatedArticleTitle] = useState(result.articleTitle);
  const [aboutSite, setUpdatedAboutSite] = useState(result.aboutSite);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  const isLoading = isLoadingHandbook || isLoadingSites;
  const history = useHistory();

  const updateHandbook = async ({
    name,
    id,
    content,
    type,
    articleTitle,
    optOutUrl,
    siteUrl,
    privacyPolicyUrl,
    aboutSite,
    isGuide,
  }) => {
    setIsUpdating(true);

    if (!isGuide) {
      id = formatDocId(name);
      if (!Boolean(id)) {
        setIsUpdating(false);
        setError('Page Name must consist of at least two words. e.g. "Valid Pagename"');
        return;
      }
    }

    try {
      const refHandBook = await db
        .collection('handbooks')
        .doc(id)
        .get();

      if (refHandBook.exists) {
        await db
          .collection('handbooks')
          .doc(id)
          .update({
            name,
            content,
            updatedAt: new Date(),
            type,
            ...(isGuide && { articleTitle, optOutUrl, siteUrl, privacyPolicyUrl, aboutSite }),
          });
      } else {
        await db
          .collection('handbooks')
          .doc(id)
          .set({
            name,
            id,
            content,
            updatedAt: new Date(),
            type,
            ...(isGuide && { articleTitle, optOutUrl, siteUrl, privacyPolicyUrl, aboutSite }),
          });
      }

      if (isGuide) {
        await db
          .collection('sites')
          .doc(id)
          .update({ handbook: id });
      }

      setIsUpdating(false);
      return history.replace('/handbook');
    } catch (e) {
      console.log(e);
      setIsUpdating(false);
      setError(`${isGuide ? 'Guide Content' : 'Article Content'} is required!`);
    }
  };

  const handbookType = id !== 'new' ? (type ? type : result?.type) : location.hash ? 'removalGuide' : 'content';

  useEffect(() => {
    setType(handbookType);
    // eslint-disable-next-line
  }, [Boolean(handbookType)]);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={40} />
      </div>
    );
  }

  const isGuide = type !== 'content';

  return (
    <Container className={classes.container} maxWidth="md">
      <Link to="/handbook" className={classes.linkAllUsers}>
        <ArrowBackIosIcon />
        Back to Handbook Table of Contents
      </Link>
      <Typography variant="h4" className={classes.mainTitleHeader}>
        {id === 'new' ? 'Create A New' : 'Edit A'} Handbook Page
      </Typography>
      <Paper className={classes.loading} elevation={0}>
        <Container className={classes.contentContainer}>
          <form
            onSubmit={e => {
              e.preventDefault();
              updateHandbook({
                name: updatedName || result.name,
                id: siteIdentifier || id,
                content: updatedContent || result.content,
                articleTitle: articleTitle || result.articleTitle,
                optOutUrl: optOutUrl || result.optOutUrl,
                privacyPolicyUrl: privacyPolicyUrl || result.privacyPolicyUrl,
                siteUrl: siteUrl || result.siteUrl,
                aboutSite: aboutSite || result.aboutSite,
                type,
                isGuide,
                isDisabled: false,
              });
            }}
          >
            <Grid className={classes.loading} container justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} className={classes.inputsContainer}>
                {!isGuide ? (
                  <></>
                ) : id === 'new' ? (
                  <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel>Site Identifier</InputLabel>
                    <Select
                      value={siteIdentifier || ''}
                      onChange={e => setSiteIdentifier(e.target.value)}
                      labelWidth={85}
                    >
                      {Boolean(sites.length) &&
                        sites.map(site => (
                          <MenuItem key={site.id} value={site.id}>
                            {site.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="h5" color="primary">
                    Site: {siteIdentifier || result.id}
                  </Typography>
                )}
                <TextField
                  required
                  className={classes.textField}
                  label="Page Name"
                  defaultValue={id === 'new' ? '' : result.name}
                  onChange={e => setUpdatedName(e.target.value)}
                  variant="outlined"
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Type</InputLabel>
                  <Select value={type} onChange={e => setType(e.target.value)} labelWidth={85}>
                    <MenuItem key="content" value="content">
                      Content
                    </MenuItem>
                    <MenuItem key="removalGuide" value="removalGuide">
                      Removal Guide
                    </MenuItem>
                  </Select>
                </FormControl>
                {isGuide && (
                  <>
                    <TextField
                      required
                      className={classes.textField}
                      label="Guide Title"
                      defaultValue={result.articleTitle}
                      onChange={e => setUpdatedArticleTitle(e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      required
                      className={classes.textField}
                      label="Site URL"
                      defaultValue={result.siteUrl}
                      onChange={e => setUpdatedSiteUrl(e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      required
                      className={classes.textField}
                      label="Opt Out URL"
                      defaultValue={result.optOutUrl}
                      onChange={e => setUpdatedOptOutUrl(e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      required
                      className={classes.textField}
                      label="Privacy Policy URL"
                      defaultValue={result.privacyPolicyUrl}
                      onChange={e => setUpdatedPrivacyPolicyUrl(e.target.value)}
                      variant="outlined"
                    />
                    <TextField
                      required
                      multiline
                      rows={4}
                      className={classes.textField}
                      label="About Site"
                      defaultValue={result.aboutSite}
                      onChange={e => setUpdatedAboutSite(e.target.value)}
                      variant="outlined"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} className={classes.editorContainer}>
                <InputLabel>{isGuide ? 'Guide Content' : 'Article Content'}</InputLabel>
                <Editor
                  placeholder={`Write some ${isGuide ? 'guide' : 'article'} content...`}
                  defaultValue={id === 'new' ? '' : result.content}
                  className={classes.editor}
                  onChange={setUpdatedContent}
                />
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="error">
                      {error}
                    </Typography>
                  </Grid>
                )}
                <Button
                  className={`${classes.button}`}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isUpdating}
                >
                  {isUpdating ? <CircularProgress size={20} /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </Container>
  );
};

export default Handbook;
