import React, { useState } from 'react';
import { Button, CircularProgress as Loading } from '@material-ui/core';
import { useAsync } from '../../../hooks';
import { Check as Success, Clear as Error } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import { functions } from '../../../firebase';

const RemoveButton = ({ alert }) => {
  const confirm = useConfirm();
  const [success, setSuccess] = useState(false);

  const [{ isLoading, error }, triggerRemover] = useAsync(async () => {
    await confirm({
      title: `Run Automatic Remover`,
      description: `Are you sure you want to run the auto-matic remover for this alert?`,
    });
    await functions.httpsCallable('triggerRemoval')({ alertId: alert.id, userId: alert.userId, siteId: alert.siteId });
    setSuccess(true);
  });

  if (!['unsupported', 'requested', 'attention'].includes(alert.type)) {
    return null;
  }

  if (!alert.site || !alert.site.hasRemoval) {
    return null;
  }

  return (
    <Button variant="outlined" onClick={triggerRemover}>
      {isLoading && <Loading />}
      {error && <Error />}
      {success && <Success />}
      Remove
    </Button>
  );
};

export default RemoveButton;
