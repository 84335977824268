import React from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-head': {
            background: theme.palette.primary.main,
            color: 'white',
            borderBottom: '1px solid #FBFBFC !important',
            fontWeight: 600,
            padding: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.5),
            },
            '& span': {
              '& svg > path': { fill: 'white' },
              '& div': {
                alignItems: 'center',
                '& div': {
                  color: 'white',
                },
              },
            },
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            fontWeight: 600,
            color: theme.palette.primary.main,
            padding: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.5),
            },
          },
          '&:nth-of-type(even)': {
            background: '#f9f9f9',
          },
        },
      },
    },
  },
}));

const ItemsTable = ({ columns, items, title }) => {
  const classes = useStyles();
  return (
    <MUIDataTable
      title={title}
      className={classes.table}
      data={items}
      columns={columns}
      options={{
        selectableRows: 'none',
        print: false,
        download: false,
        sort: true,
        rowsPerPage: 100,
        elevation: 0,
        responsive: 'stacked',
        enableNestedDataAccess: '.',
      }}
    />
  );
};

export default ItemsTable;
