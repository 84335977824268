exports.getPlan = plan => {
  const timestamp = Math.round(new Date().getTime() / 1000);

  if (!plan) {
    return 'Free';
  }
  if (plan && !plan.cycle) {
    if (plan.label === 'Premium' && plan.trialEnds.seconds > timestamp) {
      return 'Premium - Trial';
    } else {
      return 'Free';
    }
  }
  return `${plan.type[0].toUpperCase() + plan.type.substring(1)} - ${
    plan.cycle === 'biennial' ? '2-Year' : plan.cycle[0].toUpperCase() + plan.cycle.substring(1)
  }`;
};
