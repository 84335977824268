import React from 'react';
import { Hidden } from '@material-ui/core';
import SideNav from './SideNav';
import BottomNav from './BottomNav';

export default () => (
  <>
    <Hidden xsDown>
      <SideNav />
    </Hidden>
    <Hidden smUp>
      <BottomNav />
    </Hidden>
  </>
);
