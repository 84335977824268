import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    width: 120,
    padding: theme.spacing(1.4, 9.5),
    borderRadius: 15,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      width: 50,
    },
  },
  paper: {
    marginTop: theme.spacing(5),
    borderRadius: theme.spacing(5),
    boxShadow: `0px 1px 5px #f5f5f5`,
  },
  textField: {
    width: '70%',
    marginBottom: theme.spacing(1),
    '& > div': {
      '& > input': {
        color: theme.palette.link,
        '&:focus': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& > label': {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
  },
  dialog: {
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
  },
  inputsContainer: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHead: {
    '& > tr': {
      '& > th': {
        background: theme.palette.primary.main,
        color: 'white',
        '&:first-child': {
          display: 'table-cell !important',
          borderRadius: theme.spacing(1.5, 0, 0, 0),
        },
        '&:last-child': {
          borderRadius: theme.spacing(0, 1.5, 0, 0),
        },
      },
    },
  },
}));
