import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, IconButton, Typography, Divider, CircularProgress } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import UserScanSettings from './UserScanSettings';

const useStyles = makeStyles(theme => ({
  scanRemoveContainer: {
    paddingTop: theme.spacing(3),
  },
  drawer: {
    width: '100vw',
    maxWidth: theme.breakpoints.values.sm,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: '100vw',
    maxWidth: theme.breakpoints.values.sm,
    boxSizing: 'border-box',
    padding: theme.spacing(4),
  },
  closeButton: {
    width: 'max-content',
    alignSelf: 'flex-end',
  },
  contentContainer: {
    '& > span': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      '& > h5': {
        fontWeight: 500,
      },
    },
  },
  siteDescription: {
    marginBottom: theme.spacing(3),
    whiteSpace: 'normal',
  },
}));

export default ({ open, onClose, site, handbooks, isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return <CircularProgress />;
  }

  const handbook = site && site.handbook ? handbooks.find(h => h.id === site.handbook) : false;

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className={classes.contentContainer}>
        <span>
          <Typography variant="h5" color="primary">
            {site && site.id}
          </Typography>
        </span>

        <Typography className={classes.siteDescription} variant="body1" color="textSecondary">
          {site && handbook ? handbook.aboutSite : 'No Site Description in Handbook'}
        </Typography>
        <Divider />

        <div className={classes.scanRemoveContainer}>
          <Typography variant="h5" color="primary">
            Scan & Remove Functionality
          </Typography>

          <Typography className={classes.siteDescription} variant="body1" color="textSecondary">
            Scan
            <br />
            {site && site.hasRemoval && (
              <>
                Auto Remove
                <br />
              </>
            )}
            Manual Remove
          </Typography>
        </div>

        <Divider />

        <UserScanSettings site={site} />

        <Divider />
      </div>
    </Drawer>
  );
};
