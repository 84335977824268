export default month => {
  return [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'label',
      label: 'Name',
    },
    {
      name: `scans.${month}`,
      label: '# Scans',
    },
    {
      name: `recordsFound.${month}`,
      label: '# Records Found',
    },
    {
      name: `removals.${month}`,
      label: '# Removals',
    },
  ];
};
