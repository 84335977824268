import React from 'react';
import { Link } from '@material-ui/core';
import formatDate from 'date-fns/format';
import StatusDropDown from './StatusDropDown';
import RemoveButton from './RemoveButton';
import { ALERT_TYPES } from '../../../alertTypes';

export default (alerts, isVisibleOnMobile) => {
  return [
    {
      name: 'id',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Scan Date',
      options: {
        customBodyRender: timestamp => formatDate(timestamp, 'MM/dd/yyyy'),
        display: !isVisibleOnMobile,
      },
    },
    {
      name: 'site.id',
      label: 'Site',
    },
    {
      name: 'user.firstName',
      label: 'First Name',
    },
    {
      name: 'user.lastName',
      label: 'Last Name',
    },
    {
      name: 'site.label',
      label: 'Link',
      options: {
        display: !isVisibleOnMobile,
        customBodyRender: label => {
          if (!label) return null;
          return (
            <Link href={`http://www.${label}`} target="_blank" rel="noopener" color="inherit">
              {label}
            </Link>
          );
        },
      },
    },
    {
      name: 'site.hasRemoval',
      label: 'Remover Type',
      options: {
        customBodyRender: value => (value ? 'Auto' : 'Manual'),
      },
    },
    {
      name: 'type',
      label: 'Remove',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const [id] = tableMeta.rowData;
          const alert = alerts.find(a => a.id === id);

          return <RemoveButton alert={alert} />;
        },
      },
    },
    {
      name: 'type',
      label: 'Removal Status',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          const [id] = tableMeta.rowData;
          const alert = alerts.find(a => a.id === id);

          return <StatusDropDown alert={alert} />;
        },
        filterOptions: {
          names: ALERT_TYPES.map(a => a.label),
          logic: (type, filters) => {
            const names = ALERT_TYPES.find(a => a.id === type);
            if (filters.length) return !filters.includes(names.label);
            return false;
          },
        },
      },
    },
  ];
};
