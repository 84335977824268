import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';

const useLoadingStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
  },
}));

export default () => {
  const classes = useLoadingStyles();
  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <CircularProgress size={40} />
    </Grid>
  );
};
